<template>
  <v-row>

    <v-expand-transition>
      <v-col cols="12" v-if="totalAmountSistema">
        <v-card class="elevation-5 mb-5 font-weight-black" width="100%" color="grey">
          <v-toolbar color="grey" class="pr-4">
            <v-toolbar-title>
              <v-btn icon size="medium">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar-title>
            Saldo no {{ services.myAppName }}: R$ {{ formataMoedaBRL(totalAmountSistema) }}
          </v-toolbar>
        </v-card>
      </v-col>
    </v-expand-transition>

    <v-col cols="12" md="4" sm="12" v-if="reportPedidoData">
      <ChartPedidosPorDia :key="1" />
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <ChartPropostasPorDia :key="2" />
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <ChartTransacoesPorDia :key="3" />
    </v-col>
  </v-row>
</template>


<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import ChartPedidosPorDia from '@/components/charts/ChartPedidosPorDia.vue';
import ChartPropostasPorDia from '@/components/charts/ChartPropostasPorDia.vue';
import ChartTransacoesPorDia from '@/components/charts/ChartTransacoesPorDia.vue';

export default {

  components: {
    ChartPedidosPorDia,
    ChartPropostasPorDia,
    ChartTransacoesPorDia,
  },

  mixins: [
    myMixin,
  ],

  computed: {

  },

  data: () => ({

    errorMessage: null,
    store,

    isSmallScreen: false,
    totalAmountSistema: null,

    //dados dos reports:
    reportPedidoData: null,
    reportPedidoOptions: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Novos Pedidos / dia'
        },
        subtitle: {
          display: true,
          text: 'Período de 10 dias'
        },
        legend: {
          display: false,
          labels: {
            color: 'blue'
          },

        }
      },

      reportPropostaData: null,
      chartPropostaOptions: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Propostas / dia'
          },
          subtitle: {
            display: true,
            text: 'Período de 10 dias'
          },
          legend: {
            display: false,
            labels: {
              color: 'blue'
            },

          }
        }
      }
    }

  }),

  beforeMount() {
    this.isSmallScreen = this.$vuetify.display.mobile;
  },

  mounted() {
    //this.simpleServiceCall();
    this.loadBasicGlobalData();
    this.loadTotalBalance();
    // this.loadReportPedidoData();
    // this.loadReportPropostaData();
    //this.loadReportData();

  },

  methods: {

    loadTotalBalance() {

      store.dialogLoader = true;
      this.axios.get(services.serviceHost + "/bankaccount/system/balance", {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      })
        .then(response => {
          //console.log('pedidos chart: ' + JSON.stringify(response.data));
          this.totalAmountSistema = response.data;
          store.dialogLoader = false;
        })
        .catch(error => {
          console.log('chart error : ' + error);
          store.dialogLoader = false;
          this.softLogout();
        });
    },

    loadReportPedidoData() {

      store.dialogLoader = true;
      this.axios.get(services.serviceHost + "/chart/pedido/total/dia/10", {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      })
        .then(response => {
          console.log('pedidos chart: ' + JSON.stringify(response.data));

          const data = response.data;
          // Extract the dates and values from the response data
          const dates = data.map(item => item.stringDate.substring(0, 5));
          const values = data.map(item => item.total);

          this.reportPedidoData = {
            labels: dates,
            datasets: [
              {
                label: 'Total',
                data: values,
                backgroundColor: 'rgba(174, 214, 241, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },

            store.dialogLoader = false;
        })
        .catch(error => {
          console.log('chart error : ' + error);
          store.dialogLoader = false;
          this.softLogout();
        });
    },

    loadReportPropostaData() {

      store.dialogLoader = true;
      this.axios.get(services.serviceHost + "/chart/proposta/total/dia/10", {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      })
        .then(response => {
          console.log('pedidos chart: ' + JSON.stringify(response.data));

          const data = response.data;
          // Extract the dates and values from the response data
          const dates = data.map(item => item.stringDate.substring(0, 5));
          const values = data.map(item => item.total);

          this.chartData = {
            labels: dates,
            datasets: [
              {
                label: 'Total',
                data: values,
                backgroundColor: 'rgba(36, 113, 163, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },

            store.dialogLoader = false;
        })
        .catch(error => {
          console.log('chart error : ' + error);
          store.dialogLoader = false;
          this.softLogout();
        });
    },

  }

}

</script>

<style scoped></style>
