<template>
  <div>
    <v-card class="elevation-10 mb-6" :disabled="store.dialogLoader">
      <v-toolbar color="primary">
        <v-toolbar-title>{{ item.servico.nomeServico }} {{ item.id }}</v-toolbar-title>
        <v-btn icon="mdi-magnify" color="white" elevation="0" @click="openPedidoDetalhe(item)"></v-btn>
      </v-toolbar>
      <v-card-text class="px-5 pb-6">
        
        <v-row>

          <v-col cols="12" class="pb-1" v-if="item.pedidoStatus == 'RUNNING' || item.pedidoStatus == 'PAID'">
            <div class="text-center font-weight-black">
              <span v-if="item.pedidoStatus == 'RUNNING'">Trabalho em execução</span>
              <span v-else-if="item.pedidoStatus == 'PAID'">Trabalho finalizado e pago</span>
            </div>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1" v-if="item.pedidoStatus == 'RUNNING' || item.pedidoStatus == 'PAID'">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pb-1">
            <div class="">{{ item.endereco.logradouro }}, {{ item.endereco.complemento }}</div>
            <div class="">{{ item.endereco.localidade }}, {{ item.endereco.bairro }}</div>
          </v-col>
          
          <v-col cols="12" class="pt-1 pb-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1">
            <div class="">Criado em {{ item.dtCreated }} hs</div>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1">
            <div class="" v-if="item.originalPrice">Você paga R$ {{ formataMoedaBRL(item.originalPrice) }}</div>
            <div class="" v-if="!item.originalPrice">O prestador dará o preço</div>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1" v-if="item.acceptedProposta">
            <div class="font-weight-black">Proposta de {{ item.acceptedProposta.prestadorNome }} aceita</div>
            <div class="" v-if="item.acceptedProposta.prestadorTelefone">Contato : {{ item.acceptedProposta.prestadorTelefone.numero }}</div>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1" v-if="!item.acceptedProposta">
            <div class="">Pedido em aberto, recebendo propostas</div>
          </v-col>

          <v-col cols="12" class="pt-1 pb-0" v-if="!item.acceptedProposta">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pt-1 pb-1" v-if="item.propostaList && item.propostaList.length > 0">
            {{item.propostaList.length}} {{ item.propostaList.length == 1 ? 'proposta' : 'propostas' }}
          </v-col>

          <v-col cols="12" class="pt-1 pb-0" v-if="item.propostaList && item.propostaList.length > 0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pb-1" v-if="item.pedidoStatus === 'ACCEPTED'">
            <v-btn text width="100%" @click="inciarServico(item)"
              color="green-lighten-2" class="elevation-1" prepend-icon="mdi-play">
              Informar Início
            </v-btn>
          </v-col>

          <v-col cols="12" class="pb-1" v-if="item.pedidoStatus === 'RUNNING'">
            <v-btn text width="100%" @click="dialogConfirmJobFinish = true"
              color="green-lighten-2" class="elevation-1" prepend-icon="mdi-check">
              Informar Conclusão
            </v-btn>
          </v-col>

          <v-col cols="12" class="pb-1" v-if="item.propostaList && item.propostaList.length > 0">
            <v-btn text v-if="item.propostaList && item.propostaList.length > 0" 
              @click="listaPropostasDoPedido(item)" width="100%"
              color="primary" prepend-icon="mdi-magnify">
              Ver Propostas
            </v-btn>
          </v-col>

        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="">
        <v-spacer></v-spacer>

        <v-btn text v-if="item.propostaList && item.propostaList.length > 0" 
          @click="listaPropostasDoPedido(item)"  
          color="primary" variant="outlined" prepend-icon="mdi-magnify">
          Propostas
        </v-btn>
        
        <v-btn v-if="!item.acceptedProposta" icon="mdi-trash-can" color="primary" elevation="0" @click="deletePedido(item.id)"></v-btn>

      </v-card-actions> -->
    </v-card>

    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog v-model="dialogConfirmJobFinish"
          transition="dialog-bottom-transition" :key="item.id"
          fullscreen
        >
          <v-card :disabled="store.dialogLoader">
            <v-toolbar
              color="primary"
            >
                <v-toolbar-title class="">
                  Trabalho concluído {{ item.id }}
                </v-toolbar-title>
                <v-btn icon @click="dialogConfirmJobFinish = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="">
              <div class="text-h5 pa-4 text-center">
                <p>Ótimo, trabalho concluído! </p> 
              </div>
              <div class="text-h5 pa-4 text-center">
                <p>Conta para nós qual a sua avaliação do serviço:</p>
              </div>
              <div class="text-center">
                <v-rating
                  v-model="rating"
                  hover  color="primary"
                  half-increments
                ></v-rating>
              </div>
              <div>
                <v-btn text width="100%" :disabled="!rating" @click="finishJob(item)"
                  color="primary" class="elevation-1 mt-10" prepend-icon="mdi-thumb-up-outline">
                  Concluir
                </v-btn>
              </div>
              <div class="text-center mt-10" v-if="item.acceptedProposta">
                Ao concluir o serviço liberaremos o valor do pagamento para o prestador {{ item.acceptedProposta.prestadorFirstName }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
//import services from '../../ws/services';
import { store } from '../../store';

export default {

  props : {
    item : Object
  },

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen: true,

    dialogConfirmJobFinish : false,

    rating : null,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    this.rating = null;
  },

  methods: {

    finishJob(myPedido){
      myPedido.rating = this.rating;
      this.$emit('finish-job', myPedido);
      console.log('finishing job: ' + myPedido.id);
      this.dialogConfirmJobFinish = false;
      this.rating = null;
    },

    inciarServico(myPedido){

      this.$emit('iniciar-servico', myPedido);
    },

    listaPropostasDoPedido(myItem){
      this.$emit('list-propostas-pedido', myItem);
    },

    deletePedido(myItemId){

      this.$emit('deletar-pedido', myItemId);
    },

    openPedidoDetalhe(myItem){
      this.$emit('abrir-pedido-detalhe', myItem);
    },

  }

}

</script>

<style scoped>


</style>
