<template>
  <div>
    <v-expand-transition>
      <v-card class="px-2 pa-2 " v-if="pedidoList" flat>
        <v-row class="">
          
          <v-col cols="12" lg="3" md="4" sm="12" class="" v-for="item in pedidoList" :key="item.id">
            <card-pedido-info v-bind:pedido-card-info="item" v-bind:key="item.id" @open-dialog-pedido-detail="openDialogPedidoDetail($event)" />
          </v-col>

        </v-row>
      </v-card>
    </v-expand-transition>

    <modal-pedido-detail v-if="selectedPedido" :key="selectedPedido.id" @pedido-detalhe-modal-close="closeDetalheModal()"
      :item-id="null" :item-pedido="selectedPedido" :show-dialog="showDialogPedidoDetalhe" />
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import CardPedidoInfo from './CardPedidoInfo.vue';

import ModalPedidoDetail from '@/components/modal/ModalPedidoDetail.vue';

export default {

  components : {
    CardPedidoInfo,
    ModalPedidoDetail,
  },

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({
      
    errorMessage : null,
    store,

    pedidoList : null,

    selectedPedido : null,
    showDialogPedidoDetalhe : false,

  }),

  mounted() {
    this.loadAllPedidos();
  },

  methods: {

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
      this.selectedPedido = null;
    },

    openDialogPedidoDetail(myPedido){
      console.log('opening dialog pedido 2 : ' + myPedido.servico.nomeServico);
      this.selectedPedido = myPedido;
      this.showDialogPedidoDetalhe = true;
    },

    loadAllPedidos(){

      if(!this.servicoList){
        //store.dialogLoader = true;
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
          .post(services.serviceHost + "/pedido/allopenedenabledlimit/16", {}, config)
          .then(response => {
            console.log('loading pedidos : ' + response.status);
            this.pedidoList = response.data;
            //store.dialogLoader = false;
        });
      }
    },

  }

}

</script>

<style scoped>


</style>
