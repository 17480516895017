<template>
  <div>
    <v-card :disabled="store.dialogLoader" v-if="pedidoList">
      <v-toolbar color="primary">

        <v-toolbar-title>Total de {{ pedidoList.length }} Pedidos</v-toolbar-title>
        <v-btn icon large link to="/" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-card class="pa-2 mb-4">
          <v-text-field v-model="filter"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
            ></v-text-field>
        </v-card>
        
        <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="pedidoList"
          class="elevation-1"
          :search="filter"
        >
        <template v-slot:[`item.servico.nomeServico`]="{ item }">
          {{ item.raw.servico.nomeServico }}
        </template>

        <template v-slot:[`item.originalPrice`]="{ item }">
          {{ (item.raw.originalPrice ? 'R$ ' + formataMoedaBRL(item.raw.originalPrice) : '-') }}
        </template>

        <template v-slot:[`item.endereco`]="{ item }">
          {{ item.raw.endereco.logradouro }}
        </template>

        <template v-slot:[`item.propostaList`]="{ item }">
          {{ item.raw.propostaList.length }}
        </template>

        <template v-slot:[`item.acceptedProposta`]="{ item }">
          {{ (item.raw.acceptedProposta ? item.raw.acceptedProposta.prestadorNome : 'em aberto') }}
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-btn icon variant="outlined" x-small size="x-small" color="primary" @click="openDialogPedido(item.raw)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>

        <!-- <template v-slot:[`item.enabled`]="{ item }">
          <v-switch v-model="item.raw.enabled" color="primary"
            @change="changeStatus(item.raw.id)"></v-switch>
        </template> -->
        
        
        </v-data-table>

      </v-card-text>

    </v-card>

    <modal-pedido-detail-owner v-if="selectedPedido" :key="selectedPedido.id" @pedido-detalhe-modal-close="closeDetalheModal()"
      :item-id="null" :item-pedido="selectedPedido" :show-dialog="showDialogPedidoDetalhe" />
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import { VDataTable } from 'vuetify/labs/VDataTable';

import ModalPedidoDetailOwner from '@/components/modal/ModalPedidoDetailOwner.vue';

export default {

  components : {
    VDataTable,
    ModalPedidoDetailOwner,
  },

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({

    headers : [
      { title: 'Serviço', align: 'start', key: 'servico.nomeServico', sortable: true},
      { title: 'Preço', align: 'start', key: 'originalPrice', sortable: true},
      { title: 'Local', align: 'start', key: 'endereco', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Propostas', align: 'center', key: 'propostaList', sortable: true},
      { title: 'Contratante', align: 'center', key: 'ownerName', sortable: false},
      { title: 'Prestador', align: 'center', key: 'acceptedProposta', sortable: false},
      { title: 'Detalhe', align: 'center', key: 'id', sortable: false},

    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    pedidoList : null,

    showDialogPedidoDetalhe : false,
    selectedPedido : null,

    filter : null,
  }),

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadPedidos();
  },

  methods: {

    openDialogPedido(myItem){
      this.selectedPedido = myItem;
      this.showDialogPedidoDetalhe = true;
    },

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
      this.selectedPedido = null;
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPedidos();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPedidos(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/all", {}, config)
        .then(response => {
          this.pedidoList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
