<template>
  <v-card class="elevation-6" :disabled="store.dialogLoader">
    <v-toolbar color="primary">

      <v-toolbar-title>Depositar</v-toolbar-title>
      <v-btn icon large link to="/" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="">
      <v-row>

        <v-col cols="12" md="6" sm="12">
          <v-text-field class="" color="primary"
              v-model="valorDeposito"
              prepend-inner-icon="mdi-bank-plus"
              id="valorDeposito"
              label="Valor (Entre 100 e 10.000)"
              placeholder="Valor"
              maxlength="6"
              type="numeric"
              v-mask="'######'"
              :error-messages="v$.valorDeposito.$invalid ? ['Digite um valor válido'] : []">
            </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12">
          <v-text-field class="" color="primary"
              :disabled="v$.valorDeposito.$invalid"
              v-model="cartaoNumero"
              prepend-inner-icon="mdi-credit-card"
              id="cartaoNumero"
              label="Número do Cartão"
              placeholder="Número do Cartão"
              maxlength="19"
              type="numeric"
              v-mask="'#### #### #### ####'"
              :error-messages="v$.cartaoNumero.$invalid ? ['Campo obrigatório'] : []"
              >
            </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12">
          <v-text-field class="" color="primary"
              :disabled="v$.cartaoNumero.$invalid"
              v-model="cartaoTitular"
              prepend-inner-icon="mdi-account"
              id="cartaoTitular"
              label="Titular do Cartão"
              placeholder="Nome no cartão"
              maxlength="35"
              :error-messages="v$.cartaoTitular.$invalid ? ['Campo obrigatório'] : []">
            </v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
          <v-text-field class="" color="primary"
              :disabled="v$.cartaoTitular.$invalid"
              v-model="cartaoValidade"
              prepend-inner-icon="mdi-calendar"
              id="cartaoValidade"
              label="Validade (dd/aa)"
              placeholder="Validade"
              maxlength="5"
              type="numeric"
              v-mask="'##/##'"
              :error-messages="v$.cartaoValidade.$invalid ? ['Campo obrigatório'] : []">
            </v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
          <v-text-field class="" color="primary"
              :disabled="v$.cartaoValidade.$invalid"
              v-model="cartaoCVV"
              prepend-inner-icon="mdi-lock"
              id="cartaoCVV"
              label="CVV"
              placeholder="CVV"
              maxlength="6"
              type="numeric"
              v-mask="'###'"
              :error-messages="v$.cartaoCVV.$invalid ? ['Campo obrigatório'] : []">
            </v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12" v-if="endereco">
          <v-text-field class="" clearable color="primary"
            :disabled="v$.cartaoCVV.$invalid"
            @click:clear="resetFields()"
            v-model="endereco.cep"
            prepend-inner-icon="mdi-map"
            id="cep"
            label="CEP"
            hint="CEP"
            placeholder="Endereço de faturamento"
            maxlength="9"
            v-mask="'#####-###'"
            type="numberic"
            @keyup="loadEndereco()"
            :error-messages="v$.endereco.cep.$invalid ? ['Digite o CEP'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.cep.$invalid"
            v-model="endereco.uf"
            prepend-inner-icon="mdi-map-marker-radius-outline"
            id="uf"
            label="UF"
            maxlength="2"
            readonly
            :error-messages="v$.endereco.uf.$invalid ? ['Estado'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.cep.$invalid"
            v-model="endereco.localidade"
            prepend-inner-icon="mdi-map-marker-radius-outline"
            id="uf"
            label="UF"
            maxlength="2"
            readonly
            :error-messages="v$.endereco.uf.$invalid ? ['Cidade'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.localidade.$invalid"
            v-model="endereco.bairro"
            prepend-inner-icon="mdi-home-city-outline"
            id="bairro"
            label="Bairro"
            maxlength="200"
            :readonly="!allowEditAddress"
            :error-messages="v$.endereco.bairro.$invalid ? ['Digite o bairro'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.uf.$invalid"
            v-model="endereco.logradouro"
            prepend-inner-icon="mdi-home-outline"
            id="logradouro"
            label="Logradouro"
            maxlength="200"
            :readonly="!allowEditAddress"
            :error-messages="v$.endereco.logradouro.$invalid ? ['Digite o logradouro'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.uf.$invalid"
            v-model="endereco.numero"
            prepend-inner-icon="mdi-door"
            id="numero"
            type="numberic"
            label="Numero"
            maxlength="10"
            :error-messages="v$.endereco.numero.$invalid ? ['Digite o numero'] : []">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12" v-if="endereco">
          <v-text-field class="" color="primary"
            :disabled="v$.endereco.uf.$invalid"
            v-model="endereco.complemento"
            prepend-inner-icon="mdi-door"
            id="complemento"
            label="Complemento"
            maxlength="20"
            counter>
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="12" v-if="endereco"></v-col>


      </v-row>
    </v-card-text>
    <v-card-actions class="pa-5">

      <v-btn v-if="endereco"
        :disabled="v$.endereco.cep.$invalid || v$.endereco.logradouro.$invalid || v$.cartaoCVV.$invalid || store.dialogLoader"
        prepend-icon="mdi-check" color="primary" variant="elevated" block @click="deposit()">Depositar</v-btn>
      
    </v-card-actions>
  </v-card>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import { required, minLength, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import Endereco from '@/components/entity/Endereco';

export default {

  setup () {

    return { 
      v$: useVuelidate(),
    }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    valorDeposito : 100,
    cartaoTitular : null,
    cartaoNumero : null,
    cartaoValidade : null,
    cartaoCVV : null,
    cpf : null,

    endereco : null,

    allowEditAddress : false,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.simpleServiceCall();
    this.loadBasicGlobalData();

    this.endereco = new Endereco();
  },

  methods: {

    deposit(){
      console.log('making the deposit');

      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/bankaccount/user/funds/add",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: {
          endereco : this.endereco,
          valorDeposito : this.valorDeposito,
          cartaoTitular : this.cartaoTitular,
          cartaoNumero : this.cartaoNumero,
          cartaoValidade : this.cartaoValidade,
          cartaoCVV : this.cartaoCVV,
          cpf : this.cpf,
        }
      }).then(response => {
        console.log('deposit: ' + response.status)
        store.dialogLoader = false;
        store.globalSnackbarMessage = 'Depósito Feito';
        store.globalSnackbar = true;
        this.$router.push('/user/bankaccount/all');
      })
      .catch(error => {
        console.log('user logged : ' + error.status);
        //console.log('error : ' + error);
        store.dialogLoader = false;
        this.$router.push("/login");
      });
    },

    resetFields(){
      this.endereco.uf = null;
      this.endereco.localidade = null;
      this.endereco.bairro = null;
      this.endereco.logradouro = null;
      this.endereco.numero = null;
      this.endereco.complemento = null;
      this.endereco.pontoReferencia = null;
      this.allowEditAddress = false;
    },

    loadEndereco(){

      if(this.endereco.cep && this.endereco.cep.length == 9){

        store.dialogLoader = true;

        //https://opencep.com/v1/20531005.json
        this.axios
          .get('https://opencep.com/v1/' + this.endereco.cep.replaceAll('-', '') + '.json', {}, {})
          .then(response => {
          //console.log('resultado do CEP : \n' + JSON.stringify(response.data));
          store.dialogLoader = false;
          if(response.data){
            this.endereco = response.data;
          }
          store.dialogLoader = false;
        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
        });
      }
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },


  validations() {
      return {

        valorDeposito : {
          required,
        },
        cartaoTitular : {
          required,
          min: minLength(4),
        },
        cartaoNumero : {
          required,
          min: minLength(19),
          max: maxLength(19),
        },
        cartaoValidade : {
          required,
          min: minLength(5),
          max: maxLength(5),
        },
        cartaoCVV : {
          required,
          min: minLength(3),
        },

        endereco: {
          cep: {
            required,
            min: minLength(9),
            max: maxLength(9),
          },
          uf : {
            required,
            min: minLength(2),
            max: maxLength(2),
          },
          logradouro : {
            required,
            min: minLength(2),
            max: maxLength(200),
          },
          bairro : {
            required,
            min: minLength(2),
            max: maxLength(100),
          },
          localidade : {
            required,
            min: minLength(2),
            max: maxLength(100),
          },
          numero : {
            required,
            max: maxLength(10),
          }
        }
      }
  }

}

</script>

<style scoped>


</style>
