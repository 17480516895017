<template>
    <UserGrid />
</template>
  
<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import UserGrid from '@/components/grid/UserGrid.vue';

export default {
    mixins: [
        myMixin,
    ],
    computed: {
        cardTitleStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
        btnCardStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
    },

    data: () => ({

    }),

    mounted() {
        //this.simpleServiceCall();
    },

    methods: {
        simpleServiceCall() {
            const config = {
                headers: { Authorization: `Bearer ${store.currentToken}` }
            };
            this.axios
                .get(services.serviceHost + "/user/healthcheck", {}, config)
                .then(response => {
                    console.log(response.data);
            });
        }
    },
    components: { UserGrid }
}

</script>
  
<style scoped></style>
  