<template>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="4" sm="8">
                <v-dialog 
                    transition="dialog-bottom-transition"
                    :scrim="true"
                    max-width="650"
                    v-model="localShowDialog"
                    persistent
                    :fullscreen="isSmallScreen"
                    >
                    <v-card :disabled="store.dialogLoader">
                        <v-toolbar
                        color="primary"
                        >
                            <v-toolbar-title class="">
                                {{ titleMessage }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeModal">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="body-1 pa-6 pt-10">
                            
                            <v-row>
                                
                                <v-col cols="12">
                                    <div class="text-center text-title text-sm-h3 text-h4 font-weight-bold" style="color : grey;">
                                        R$ {{ formataMoedaBRL(valorSelecionado) }}
                                    </div>
                                </v-col>
                                <!-- formataMoedaBRL(valor) -->

                                <v-col cols="12">
                                    <v-slider
                                        v-model="valorSelecionado"
                                        step="10"
                                        show-ticks="always"
                                        :min="valorMinimo"
                                        :max="valorMaximo"
                                    ></v-slider>
                                </v-col>
                                
                                <v-col :cols="isSmallScreen ? 6 : 3">
                                    <v-btn text @click="valorSelecionado += 5" :flat="isSmallScreen" outlined
                                        color="primary" width="100%" :size="isSmallScreen ? 'x-large' : 'large'">
                                        + 5
                                    </v-btn>
                                </v-col>
                                <v-col :cols="isSmallScreen ? 6 : 3">
                                    <v-btn text @click="valorSelecionado += 10" :flat="isSmallScreen" outlined 
                                        color="primary" width="100%" :size="isSmallScreen ? 'x-large' : 'large'">
                                        + 10
                                    </v-btn>
                                </v-col>
                                <v-col :cols="isSmallScreen ? 6 : 3">
                                    <v-btn text @click="valorSelecionado += 50" :flat="isSmallScreen" outlined 
                                        color="primary" width="100%" :size="isSmallScreen ? 'x-large' : 'large'">
                                        + 50
                                    </v-btn>
                                </v-col>
                                <v-col :cols="isSmallScreen ? 6 : 3">
                                    <v-btn text @click="valorSelecionado += 100" :flat="isSmallScreen" outlined 
                                        color="primary" width="100%" :size="isSmallScreen ? 'x-large' : 'large'">
                                        + 100
                                    </v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>


                        </v-card-text>

                        <v-card-actions class="pa-5 pt-0">
                        <v-row>
                            <v-col cols="12">
                                <v-btn color="primary" class="px-4" :disabled="valorSelecionado == 0"
                                    @click="saveItem"
                                    width="100%" 
                                    arge variant="flat">
                                    Salvar</v-btn> 

                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" class="px-4" :disabled="valorSelecionado == 0"
                                    @click="valorSelecionado = 50"
                                    width="100%"
                                    arge variant="outlined">
                                    Zerar</v-btn>                                               
                            </v-col>
                        </v-row>    
                        <!-- <v-btn color="primary" class="px-4" :disabled="valorSelecionado == 0"
                            @click="valorSelecionado = 50"
                            width="40%"
                            arge variant="outlined">
                            Zerar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="px-4" :disabled="valorSelecionado == 0"
                            @click="saveItem"
                            width="40%"
                            arge variant="outlined">
                            Salvar</v-btn> -->
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  //import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  //import Categoria from '../entity/Categoria';
  
  export default {

    setup () {
        return { v$: useVuelidate() }
    },

    props: {
        titleMessage : String,
        valor : Number,
        showDialog : Boolean,
    },
  
    mixins: [
      myMixin,
    ],

    mounted (){
        this.isSmallScreen = this.$vuetify.display.mobile;
    },
  
    beforeMount (){
        if(this.valor){
            this.valorSelecionado = this.valor;
        }else{
            this.valorSelecionado = 50;
        }
      
    },

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,

        valorSelecionado: 50,
        valorMinimo : 50,
        valorMaximo : 1000,
    }),
  
    methods: {
  
      saveItem(){

        this.$emit('item-modal-valor-moeda-save', this.valorSelecionado);
        this.valorSelecionado = 50;
      },

      closeModal(){
        this.$emit('item-modal-valor-moeda-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  