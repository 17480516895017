<template>
  <v-card v-if="myTransactionList">
    <v-toolbar color="primary">

      <v-toolbar-title>Transações</v-toolbar-title>
      <v-btn icon large link to="/" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>

      <v-text-field v-model="filter" class="mb-2"
        prepend-inner-icon="mdi-magnify" width="300"
        placeholder="digite para filtrar..."
      ></v-text-field>

      <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="myTransactionList"
          class="elevation-1"
          :search="filter"
        >
        <template v-slot:[`item.amount`]="{ item }">
          R$ {{ formataMoedaBRL(item.raw.amount) }}
        </template>

        <template v-slot:[`item.transactionType`]="{ item }">
          <span v-if="item.raw.transactionType.toLowerCase() == 'transfer_in'">Transferência recebida</span>
          <span v-else-if="item.raw.transactionType.toLowerCase() == 'transfer_out'">Transferência para terceiros</span>
          <span v-else-if="item.raw.transactionType.toLowerCase() == 'deposit'">Depósito</span>
          <span v-else-if="item.raw.transactionType.toLowerCase() == 'withdrawal'">Saque</span>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import { VDataTable } from 'vuetify/labs/VDataTable';

export default {

  mixins: [
    myMixin,
  ],

  components : {
    VDataTable,
  },

  computed : {
    
  },

  data: () => ({

    headers : [
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Titular', key: 'ownerName', sortable: true},
      { title: 'Conta', key: 'accountNumber', sortable: false},
      { title: 'Tipo', align: 'center', key: 'transactionType', sortable: true},
      { title: 'Valor', align: 'center', key: 'amount', sortable: false},
    ],

    filter : null,
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    myTransactionList : null,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.loadMyTransactions();
    this.loadBasicGlobalData();
  },

  methods: {

    async loadMyTransactions(){

      if(store.userLogged){
        store.dialogLoader = true;
        this.axios.get(services.serviceHost + "/bankaccount/all", {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        })
        .then(response => {
            //console.log('transactions: \n' + JSON.stringify(response.data));
            this.myTransactionList = response.data;
            store.dialogLoader = false;
        })
        .catch(error => {
          console.log('load Transactions : ' + error.status);
          store.dialogLoader = false;
          store.showDialogError;
          this.softLogout();
        });
      }
    },

  }

}

</script>

<style scoped>


</style>
