<template>
  <v-card class="pb-4" v-if="currentEndereco && currentEndereco.logradouro">
    <v-card-title class="text-body-2 font-weight-bold" color="primary">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-10">
      <v-row>

        <v-col cols="12" class="pa-0">
          {{ currentEndereco.logradouro }}, {{ currentEndereco.numero }}
        </v-col>
        <v-col cols="12" class="pa-0">
          {{ currentEndereco.bairro }}
        </v-col>
        <v-col cols="12" class="pa-0">
          {{ currentEndereco.localidade }}
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-actions class="" v-if="showDelete">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="removeEndereco()">
        <v-icon left>mdi-trash-can</v-icon>
        Remover
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  props : {
    title : String,
    currentEndereco : Object,
    showDelete : Boolean,
  },

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({
      
    errorMessage : null,
    store,
  }),

  mounted() {
    //this.simpleServiceCall();
  },

  methods: {

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    },

    removeEndereco(){
      this.$emit('box-endereco-info-remove');
    },

  }

}

</script>

<style scoped>


</style>
