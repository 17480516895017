<template>
  <v-app>
    <v-navigation-drawer disable-resize-watcher v-model="drawer" app width="400">
      <v-card flat class="mx-auto" max-width="350">

        <!-- menu lateral  -->
        <v-list density="compact" active-color="primary" active-class="font-weight-bold">

          <v-list-item to="/" link class="" @click="drawer = false">
            <template v-slot:prepend>
              <v-icon class="mr-3">mdi-home</v-icon>
            </template>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <!-- menu do admin -->
          <v-divider class=""></v-divider>

          <v-list-group value="Actions" v-if="store.userLogged && store.userLogged.roleAdmin">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Administrador"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-account-tie</v-icon>
                </template>
              </v-list-item>
            </template>

              <v-list-item class="" @click="drawer = false"
                title="Pedidos"
                to="/admin/pedido/list"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-wrench-outline</v-icon>
                </template>
              </v-list-item>


              <v-list-item class="" @click="drawer = false"
                title="Propostas"
                to="/admin/proposta/list"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-file-document-edit-outline</v-icon>
                </template>
              </v-list-item>

              <v-list-item class=""  @click="drawer = false"
                title="Serviços"
                to="/admin/servico"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-tools</v-icon>
                </template>
              </v-list-item>

              <v-list-item class="" @click="drawer = false"
                title="Categorias"
                to="/admin/categoria"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-format-list-bulleted</v-icon>
                </template>
              </v-list-item>

              <v-list-item class="" @click="drawer = false"
                title="Usuários"
                to="/admin/users"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-account-multiple</v-icon>
                </template>
              </v-list-item>

          </v-list-group>

          <v-list-group value="Actions" v-if="store.userLogged && (store.userLogged.roleAdmin || store.userLogged.roleFinanceiro)">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Financeiro"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-cash-100</v-icon>
                </template>
              </v-list-item>
            </template>

              <v-list-item class="" @click="drawer = false"
                title="Dashboard"
                to="/financeiro/dashboard"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-monitor-dashboard</v-icon>
                </template>
              </v-list-item>

              <v-list-item class="" @click="drawer = false"
                title="Transações do Sistema"
                to="/financeiro/transactions/all"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-swap-horizontal-bold</v-icon>
                </template>
              </v-list-item>

            </v-list-group>

          <v-list-group value="Actions" v-if="store.userLogged">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Minha Conta"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-account</v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item class="" @click="drawer = false"
              title="Dados Cadastrais"
              to="/user/account/detail"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-clipboard-account-outline</v-icon>
              </template>
            </v-list-item>

            <v-list-item class="" @click="drawer = false" v-if="store.myPedidosCount > 0"
              title="Meus Pedidos"
              to="/user/pedido/list"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-account-tie-voice-outline</v-icon>
              </template>
            </v-list-item>

            <v-list-item class="" @click="drawer = false" v-if="store.myPropostasCount > 0"
              title="Minhas Propostas"
              to="/user/proposta/list"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-hammer-wrench</v-icon>
              </template>
            </v-list-item>

            <v-list-item class="" @click="drawer = false"
              title="Depositar"
              to="/user/bankaccount/addmoney"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-cash</v-icon>
              </template>
            </v-list-item>

            <v-list-item class="" @click="drawer = false" v-if="store.notificationUnreadList && store.notificationUnreadList.length > 0"
              title="Notificações"
              to="/user/notification/all"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-message-alert-outline</v-icon>
              </template>
            </v-list-item>

            <!-- <v-list-item class="" @click="logout(); drawer = false" v-if="store.userLogged"
              title="Logout"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-arrow-left-circle-outline</v-icon>
              </template>
            </v-list-item> -->

          </v-list-group>

          <v-list-group value="Actions" v-if="store.userLogged && store.totalUserTransactions && store.totalUserTransactions > 0">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Minha Carteira"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">mdi-cash-multiple</v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item class="" @click="drawer = false"
              title="Minhas Transações"
              to="/user/bankaccount/all"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-account-cash-outline</v-icon>
              </template>
            </v-list-item>

          </v-list-group>

          <v-list-item class="" @click="logout(); drawer = false" v-if="store.userLogged"
            title="Logout"
          >
            <template v-slot:prepend>
              <v-icon class="mr-3">mdi-arrow-left-circle-outline</v-icon>
            </template>
          </v-list-item>

          <!-- <v-list-group value="Users">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-account-tie"
                title="Administrador"
              ></v-list-item>
            </template>

            <v-list-item class="ml-n10"
              title="Categorias"
              :prepend-icon="icon"
              :value="title"
            ></v-list-item>
            <v-list-item class="ml-n10"
              title="Categorias"
            >
              <template v-slot:prepend>
                <v-icon>mdi-list-box</v-icon>
              </template>
            </v-list-item>

          </v-list-group> -->


        </v-list>

      </v-card>

    </v-navigation-drawer>

    <!-- <v-app-bar app style="background : #2b6777;" dark> -->
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="d-none d-sm-flex link-no-style">
        <v-btn class="text-none px-4 no-active" size="x-large" link to="/" >
          {{ services.myAppName }} :: Plataforma de Serviços
        </v-btn>
        <!-- <a class="link-no-style" href="/">{{ services.myAppName }} :: Plataforma de Serviços</a> -->
      </v-toolbar-title>
      <v-toolbar-title class="d-sm-none d-flex">
        <v-btn class="text-none pa-0" size="x-large" link to="/">
          {{ services.myAppName }}
        </v-btn>
        <!-- <a class="link-no-style" href="/">{{ services.myAppName }}</a> -->
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-divider vertical class="sm-flex d-none ml-3 mr-3" v-if="!store.userLogged"></v-divider>
      <v-btn class="d-none d-sm-flex" link to="/account/new" v-if="!store.userLogged">Criar Conta</v-btn>

      <v-divider vertical class=" ml-3 mr-3"></v-divider>
      <v-btn link to="/login" v-if="!store.userLogged">Login</v-btn>

      <v-menu class="d-none d-sm-flex">
        <template v-slot:activator="{ props }">
          <v-btn v-if="store.userLogged" prepend-icon="mdi-account" class="px-4 d-none d-sm-flex" rounded="lg" large v-bind="props">
            {{ store.userLogged.fullName }}
          </v-btn>
        </template>
        <v-list density="compact">

          <v-list-item link to="/user/account/detail">
            <v-list-item-title>Meu Perfil</v-list-item-title>
          </v-list-item>

          <v-list-item @click="changeMyPassword = true">
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu class="d-sm-none d-flex">
        <template v-slot:activator="{ props }">
          <v-btn v-if="store.userLogged" icon class="px-4 d-sm-none d-flex" rounded="lg" large v-bind="props">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list density="compact">

          <v-list-item link to="/user/account/detail">
            <v-list-item-title>Meu Perfil</v-list-item-title>
          </v-list-item>

          <v-list-item @click="changeMyPassword = true">
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider vertical class=" ml-3 mr-3" v-if="store.userLogged && store.notificationUnreadList && store.notificationUnreadList.length > 0"></v-divider>

      <v-expand-x-transition>
        <v-btn link rounded="lg" large icon to="/user/notification/all" v-if="store.userLogged && store.notificationUnreadList && store.notificationUnreadList.length > 0">
          <v-icon v-if="store.totalNotificationsUnread < 1">mdi-bell-outline</v-icon>
          <v-icon v-if="store.totalNotificationsUnread >= 1" color="#FFEC33">mdi-bell-ring-outline</v-icon>
        </v-btn>
      </v-expand-x-transition>

      <!-- <v-menu class="" transition="slide-x-transition" location="bottom left" v-model="notificationMenuOpened">
        <template v-slot:activator="{ props }">
          <v-btn link v-if="store.userLogged" rounded="lg" large icon v-bind="props">
            <v-icon v-if="store.totalNotificationsUnread < 1">mdi-bell-outline</v-icon>
            <v-icon v-if="store.totalNotificationsUnread >= 1" color="#FFEC33">mdi-bell-ring-outline</v-icon>
          </v-btn>
        </template>

        <v-list density="compact">

          <v-list-item v-for="(item, index) in store.notificationUnreadList" v-bind:key="item.id"
            link :to="item.messageLink">
            <v-list-item-title>
              <v-icon color="primary" v-if="!item.viewed">mdi-message-alert</v-icon>
              <v-icon color="grey-lighten-2" v-if="item.viewed">mdi-message-alert-outline</v-icon>
              {{ item.dtCreatedFriendly }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.messageText }}</v-list-item-subtitle>
            <v-list-item-action class="pt-2 pb-2" v-if="index < (store.notificationUnreadList.length - 1)">
              <v-divider  ></v-divider>
            </v-list-item-action>
          </v-list-item>

        </v-list>

      </v-menu> -->

    </v-app-bar>

    
    <v-main>
      <v-container
        class=""
        fluid
        style=""
      >
      <router-view v-slot="{ Component, route }">
        <transition name="slide-x-transition" mode="out-in">
          <div :key="route.name">  
            <component :is="Component" :key="route.path" />
          </div>
        </transition>
      </router-view>
        </v-container>
    </v-main>

    <!-- dialog de erro -->
    <v-dialog
      transition="dialog-bottom-transition"
      width="auto"
      v-model="store.showDialogError"
    >
      <v-card>
        <v-toolbar
          color="error"
          title="Algo deu errado..."
        ></v-toolbar>
        <v-card-text>
          <div class="body-1 pa-6 text-center">Opa, desculpe. Algo de errado aconteceu aqui. <br />
            Pode ter sido um problema na conexão, na rede ou no nosso sistema. <br />
            Aguarde um pouco e tente novamente, ok?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="store.showDialogError = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- atualiza pass -->
    <v-dialog
      transition="dialog-bottom-transition"
      width="450"
      v-if="store.userLogged"
      v-model="store.userLogged.redefinePassword"
      persistent
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary"
          title="Atualizar senha"
        ></v-toolbar>
        <v-card-text>
          <div class="body-1 pa-6 text-center">Você acessou a plataforma com uma senha provisória.<br /> Vamos atualizar sua senha?</div>

          <v-text-field class="mb-5" variant="outlined" 
            v-model="newPwd"
            id="newPwd"
            label="Senha"
            maxlength="15"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show1 = !show1"
            counter
            :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []">
          </v-text-field>

          <v-text-field class="mb-5" variant="outlined" 
            :disabled="v$.newPwd.$invalid"
            v-model="confirmNewPwd"
            id="confirmNewPwd"
            label="Confirme a Senha"
            maxlength="15"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show2 = !show2"
            counter
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []">
          </v-text-field>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn prepend-icon="mdi-check" class="px-4" rounded="lg" large 
          :disabled="v$.confirmNewPwd.$invalid"
            :style="btnCardStyle" elevation="2"
            width="100%" @click="updatePwd">
            Atualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      transition="dialog-bottom-transition"
      width="450" 
      v-if="store.userLogged"
      v-model="changeMyPassword"
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary" class="pr-5"
        >
          <v-toolbar-title>
            Redefinir Senha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="mb-5" variant="outlined" 
            v-model="newPwd"
            id="newPwd"
            label="Senha"
            maxlength="20"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show1 = !show1"
            counter
            :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []">
          </v-text-field>

          <v-text-field class="mb-5" variant="outlined" 
            :disabled="v$.newPwd.$invalid"
            v-model="confirmNewPwd"
            id="confirmNewPwd"
            label="Confirme a Senha"
            maxlength="20"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show2 = !show2"
            counter
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []">
          </v-text-field>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="px-4" 
            large text flat
            @click="changeMyPassword = false">
            Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn prepend-icon="mdi-check" class="px-4"
            large text color="primary" flat
            :disabled="v$.confirmNewPwd.$invalid"
            @click="updatePwd">
            Atualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- loader -->
    <v-dialog
      v-model="store.dialogLoader"
      :scrim="false"
      persistent
      width="300"
    >
      <v-card
        color="primary"
      >
        <v-card-text>
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent
      transition="dialog-bottom-transition"
      v-model="store.dialogRecoverPwd"
      width="500"
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar color="primary" class="pr-4">

          <v-toolbar-title>Recuperar Senha</v-toolbar-title>
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text class="pa-10">
          <v-text-field class="mb-5" clearable color="primary"
              v-model="emailRecoverPwd"
              prepend-icon="mdi-email-outline"
              id="email"
              label="Email de Cadastro"
              placeholder="Entre com seu email"
              maxlength="150"
              @click:clear="errorMessageMail = null"
              :error-messages="v$.emailRecoverPwd.$invalid ? ['Digite um email válido'] : []">
            </v-text-field>
        </v-card-text>
        <v-card-actions class="">
          <v-btn 
            variant="text" color="primary"
            @click="store.dialogRecoverPwd = false"
          >Fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="v$.emailRecoverPwd.$invalid"
            variant="text" color="primary"
            @click="sendRecoverMessage"
          >Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- global snackbar  -->
    <v-snackbar
      v-model="store.globalSnackbar"
      close-delay="2000"
      location="top"
      class="text-center"

    >
      <div class="text-center">{{ store.globalSnackbarMessage }}</div>
    </v-snackbar>

  </v-app>    
</template>

<script>
import router from './router'
import { store } from './store'
import services from './ws/services'
import { required, minLength, sameAs, email, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { myMixin } from './mixin';

import ModalCriaPedido from './components/modal/ModalCriaPedido.vue'

import { aesUtil } from './aes'

import 'leaflet-css';

export default {

  components : {
    ModalCriaPedido,

  },

  router,

  setup () {
    return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  data: () => ({

    show1 : false,
    show2 : false,

    newPwd : null,
    confirmNewPwd : null,

    drawer: false,
    store,

    emailRecoverPwd : null,
    changeMyPassword : false,

    showNovoPedidoModal : false,

    services,

    isSmallScreen : false,

    intervalId: null,

    notificationMenuOpened : false,

  }),

  watch : {

    notificationMenuOpened(newValue){
      //console.log('mudou menu : ' + newValue);
      if(!newValue){
        this.abriuNotificacoes();
      }
    }
  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

    clearInterval(this.intervalId);

  },

  mounted (){

    document.addEventListener("deviceready", this.onDeviceReady, false);

    if(this.isSmallScreen){
      if(!store.userLogged && !store.correntToken){
        console.log('lets log-in!!');

        var storage = window.localStorage;
        let storedUsername = storage.getItem('username');
        
        if(storedUsername && storedUsername.length > 10){
          let storedPassword = storage.getItem('password');

          // this.form.fieldn = aesUtil.decryptString(services.myphrase, storedUsername);
          // this.form.fieldp = aesUtil.decryptString(services.myphrase, storedPassword);
          this.processLogin(aesUtil.decryptString(services.myphrase, storedUsername), aesUtil.decryptString(services.myphrase, storedPassword));
        }
      }
    }

    // colocar todos os métodos recorrentes aqui nessa estrutura:
    this.intervalId = setInterval(() => {
      this.time++;
      console.log('***** checking notifications at ' + new Date());
      this.loadNotifications();
    }, 300000);
  },

  methods : {

    abriuNotificacoes(){

      console.log('abriu notificacoes');
      this.markAllNotificationsRead();
    },

    onDeviceReady() {
      window.plugins.safearea.get(this.successCallback, this.errorCallback);
      //window.open = this.$cordova.InAppBrowser.open;
      console.log('pronto aqui');
      console.log("navigator.geolocation works well");
      
      
      window.plugins.navigator.geolocation.getCurrentPosition(this.gpsOnSuccess,
                                         [this.gpsOnError],
                                         [{ maximumAge: 3000, timeout: 5000, enableHighAccuracy: true }]);
      //window.open = cordova.InAppBrowser.open;
    },

    gpsOnSuccess(position){
      console.log('Latitude: '          + position.coords.latitude          + '\n' +
        'Longitude: '         + position.coords.longitude         + '\n' +
        'Altitude: '          + position.coords.altitude          + '\n' +
        'Accuracy: '          + position.coords.accuracy          + '\n' +
        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
        'Heading: '           + position.coords.heading           + '\n' +
        'Speed: '             + position.coords.speed             + '\n' +
        'Timestamp: '         + position.timestamp                + '\n');
    },

    gpsOnError(error){
      console.log('code: '    + error.code    + '\n' + 'message: ' + error.message + '\n');
    },
    
    successCallback(result) {
      console.log('plugins: \n' + JSON.stringify(window.plugins));
      console.log(result);
    },

    errorCallback(result) {
      console.log(result);
    },

    pedidoModalClose(){
        console.log('fechou a modal');
        this.showNovoPedidoModal = false;
    },

    sendRecoverMessage(){

      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/user/resetsenha",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          email : this.emailRecoverPwd,
        }
      }).then(response => {
        console.log('recover pwd, result : ' + response.satus);
        store.dialogLoader = false;
        store.dialogRecoverPwd = false;
        store.globalSnackbarMessage = "Verifique seu email"
        store.globalSnackbar = true;
        this.$router.push("/");
        //this.reloadUserData();
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.dialogRecoverPwd = false;
        store.showDialogError = true;
      });
    },

    updatePwd(){
      
      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/atualizasenha",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          id : store.userLogged.id,
          novaSenha : this.newPwd,
        }
      }).then(response => {
        store.userLogged = response.data;
        store.dialogLoader = false;
        store.globalSnackbarMessage = "Senha atualizada"
        store.globalSnackbar = true;
        this.changeMyPassword = false;
        this.reloadUserData();
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.showDialogError = true;
      });
    },

  },

  validations() {
    return {
      newPwd: {
        required,
        min: minLength(6)
      },
      confirmNewPwd: {
        required,
        min: minLength(6),
        sameAs: sameAs(this.newPwd)
      },
      emailRecoverPwd : {
        required, email, 
        min: minLength(6),
        max: maxLength(150),
      },
    }
  }
}
</script>

<style>

app {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.body {
  /* font-family: 'El Messiri', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.8s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

.align-right{
  text-align:right;
}
.align-center{
  text-align:center;
}
.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.switch-center {
  display: flex;
  justify-content: center;
}

.link-no-style {
  text-decoration: none;
  color: inherit;
}

.floating-button {
  position: fixed;
  width: 100%; /* set a width for the div */
  margin: auto; /* set the margin to auto horizontally */
  text-align: center;
  bottom: 16px;
  z-index: 999;
}

.no-active.v-btn--active::before {
  opacity: 0;
}

.no-active:focus::before {
  opacity: 0 !important;
}




</style>