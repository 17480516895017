<template>
    <div>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="4" sm="8">
                <v-dialog 
                    transition="dialog-bottom-transition"
                    :scrim="true"
                    max-width="600"
                    v-model="localShowDialog"
                    :fullscreen="isSmallScreen"
                    persistent
                    >
                    <!-- <v-card title="opa opa aop">
                      aqui aqui aqui
                    </v-card> -->
                    <v-card :disabled="store.dialogLoader" v-if="selectedItem">
                        <v-toolbar
                        color="primary"
                        :title="selectedItem.servico.nomeServico"
                        >
                            <v-btn icon @click="closeModal">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="pa-4 pt-4 pb-12">
                            
                            <v-row>

                              <v-col cols="12" class="pb-0 pt-4 text-center">
                               Pedido criado em {{ selectedItem.dtCreated }}
                              </v-col>

                              <v-col cols="12" class="">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0">
                                {{ selectedItem.endereco.logradouro }}, {{ selectedItem.endereco.complemento }}
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                {{ selectedItem.endereco.bairro }}
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                {{ selectedItem.endereco.localidade }}, {{ selectedItem.endereco.uf }}
                              </v-col>

                              <v-col cols="12" class="">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0 text-justified text-body-1">
                               <span v-if="selectedItem.propostaList.length == 1">O pedido teve {{ selectedItem.propostaList.length }} proposta</span>
                               <span v-if="selectedItem.propostaList.length > 1">O pedido teve {{ selectedItem.propostaList.length }} propostas</span>
                               <span v-if="selectedItem.propostaList.length == 0">O pedido ainda não teve propostas</span>
                              </v-col>

                              <v-col cols="12" class="">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0 text-justified text-body-1">
                                Descrição: <br />
                                <span class="text-body-2 font-italic">{{ selectedItem.description }}</span>
                              </v-col>

                              <v-col cols="12" class="">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0 text-justified font-weight-black" v-if="selectedItem.myPrice">
                                Valor oferecido R$ {{ formataMoedaBRL(selectedItem.originalPrice) }}
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0 text-justified" v-else>
                                <span v-if="!selectedItem.acceptedProposta">Prestador faz a proposta.</span>
                                <span v-if="selectedItem.acceptedProposta">Proposta aceita: R$ {{ formataMoedaBRL(selectedItem.acceptedProposta.offeredPrice) }}</span>
                              </v-col>

                              <v-col cols="12" class="" v-if="selectedItem.acceptedProposta">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0 text-justified" v-if="selectedItem.acceptedProposta">
                                Prestador: {{ selectedItem.acceptedProposta.prestadorNome }}<br />
                                <span v-if="selectedItem.acceptedProposta.prestadorTelefone">Telefone {{ selectedItem.acceptedProposta.prestadorTelefone.tipo }}: {{ selectedItem.acceptedProposta.prestadorTelefone.numero }}</span>
                              </v-col>

                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'
  
  export default {

    setup () {
        return { v$: useVuelidate() }
    },

    props: {
        titleMessage : String,
        itemId : Number,
        itemPedido : Object,
        showDialog : Boolean,
    },
  
    mixins: [
      myMixin,
    ],
  
    beforeMount (){
      //this.selectedItem = null;
      this.isSmallScreen = this.$vuetify.display.mobile;
      //console.log("************** this.itemId: " + this.itemId);
      
    },

    mounted(){
      if(this.itemId){

      //store.dialogLoader = true;
      const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .post(services.serviceHost + "/pedido/load/" + this.itemId, {}, config)
            .then(response => {
            //console.log(response.data);
            this.selectedItem = response.data;
            //store.dialogLoader = false;
        });
      }else{
        //console.log('************ itemPedido: ' + this.itemPedido.id);
        this.selectedItem = this.itemPedido;
      }
    },

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,
    }),
  
    methods: {

      enviaProposta(){

        //console.log('salvando pedido : \n' + JSON.stringify(this.pedido));
        store.pedidoProposal = this.selectedItem;
        store.redirectPage = '/user/pedido/proposta';
        this.pedidoProposta();
      },
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('pedido-detalhe-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  