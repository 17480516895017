<template>
    <v-card :disabled="store.dialogLoader">
        <v-toolbar color="primary" title="Serviços">
            <v-spacer></v-spacer>
            <v-btn icon @click="addItem">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <div class="">
                <v-row justify="end">
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field
                            v-model="filters['global'].value" placeholder="Filtrar..." maxlength="10"
                            prepend-inner-icon="mdi-magnify" clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <DataTable :value="itemList" tableStyle="min-width: 50rem" data-key="id" class="p-datatable-sm"
                v-model:filters="filters" :loading="loading" showGridlines
                :globalFilterFields="['nomeServico', 'enabled']" 
                :paginator="itemList && itemList.length > 10" :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]">
                
                <Column field="nomeServico" sortable header="Nome" frozen class="font-bold"></Column>
                <Column field="dtCreated" sortable header="Data de Criação"></Column>
                <Column header="Categorias" class="text-center" header-class="align-center">
                    <!-- <template #body="slotProps">
                        <div v-for="myCategoria in slotProps.data.categoriaList" :key="myCategoria.id">
                            {{ myCategoria.nomeCategoria }}
                        </div>
                    </template> -->
                    <template #body="slotProps">
                        <v-tooltip
                            v-model="slotProps.data.show"
                            location="start"
                        >
                        <template v-slot:activator="{ props }">
                            <v-btn icon color="primary" small v-bind="props">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <div v-for="myCategoria in slotProps.data.categoriaList" :key="myCategoria.id">
                            {{ myCategoria.nomeCategoria }}
                        </div>
                        </v-tooltip>    
                    </template>
                </Column>
                <Column header="Editar" class="text-center">
                    <template #body="slotProps">
                        <v-btn icon color="primary" small @click="editItem(slotProps.data)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                    </template>
                </Column>
                <Column header="Ativo" class="text-center" header-class="align-center">
                    <template #body="slotProps">
                        <v-switch class="switch-center"
                            v-model="slotProps.data.enabled"
                            color="primary"
                            hide-details
                            @change="changeItemStatus(slotProps.data)"
                        ></v-switch>
                        
                    </template>
                    
                </Column>
            </DataTable>
            

        </v-card-text>
    </v-card>

    <ModalServico v-bind:show-dialog="showItemModal"
        @item-modal-close="itemModalClose"  @item-modal-save="itemModalSave($event)"
        @update-show-dialog="updateShowDialog($event)"
        :key="selectedItem && selectedItem.id ? selectedItem.id : Math.random()" v-if="selectedItem"
        :itemId="selectedItem.id" :title-message="selectedItem.id ? 'Editar Servico' : 'Novo Servico'" />
</template>
  
<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import ModalServico from '../modal/ModalServico.vue';

import { FilterMatchMode } from "primevue/api";

import Servico from '../entity/Servico';


export default {

    components : {
        ModalServico,
    },

    mixins: [
        myMixin,
    ],
    computed: {
        cardTitleStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
        btnCardStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
    },

    mounted(){

        this.reloadItemList();
    },  

    data: () => ({

        store,

        itemList : null,
        showItemModal : false,
        show : false,

        search : null,
        loading : false,
        selectedItem : null,

        headers: [
            { text: "Nome", value: "nomeServico", sortable: true },
        ],

        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nomeServico: { value: null, matchMode: FilterMatchMode.CONTAINS },
            enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
        },
    }),

    methods: {

        buildObject(){
            return new Servico();
        },

        changeItemStatus(myItem){
            store.dialogLoader = true;

            this.axios({
              method: "post",
              url: services.serviceHost + "/servico/changestatus" + "/" + myItem.id,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.currentToken}`
              },
              data: myItem
            }).then(response => {
              //console.log('user logged : \n' + JSON.stringify(response.data));
              console.log('atualizou item: ' + response.data.id);
              this.reloadItemList();
              store.globalSnackbarMessage = "Item atualizado"
              store.globalSnackbar = true;
            })
            .catch(error => {
              console.log(error.message);
              this.showItemModal = false;
              store.showDialogError = true;
              this.logout();
            });
        },

        reloadItemList() {
            store.dialogLoader = true;
            const config = {
                headers: { Authorization: `Bearer ${store.currentToken}` }
            };
            this.axios
                .post(services.serviceHost + "/servico/all", {}, config)
                .then(response => {
                //console.log(response.data);
                this.itemList = response.data;
                store.dialogLoader = false;
            })
            .catch(error => {
                console.log(error.message);
                store.dialogLoader = false;
                this.loading = false;
                store.showDialogError = true;
                this.logout();
            });
        },

        editItem(myItem){
            console.log('editando item : ' + myItem.nomeServico);
            this.selectedItem = myItem;
            this.showItemModal = true;
        },  

        addItem(){
            console.log('adicionando item');
            this.selectedItem = this.buildObject();
            //this.showItemModal = true;
        },

        itemModalSave(myItem){
            console.log('vai salvar item: ' + myItem.nomeServico);
            this.showItemModal = false;
            this.selectedItem = null;

            this.axios({
              method: "post",
              url: services.serviceHost + "/servico/save",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.currentToken}`
              },
              data: myItem
            }).then(response => {
              //console.log('user logged : \n' + JSON.stringify(response.data));
              console.log('salvou item: ' + response.data.id);
              store.dialogLoader = false;
              this.reloadItemList();
              store.globalSnackbarMessage = "Serviço salvo"
              store.globalSnackbar = true;
            })
            .catch(error => {
              console.log(error.message);
              this.showItemModal = false;
              store.showDialogError = true;
              this.logout();
            });
        },

        itemModalClose(){
            console.log('fechou a modal');
            this.showItemModal = false;
            this.selectedItem = null;
            this.reloadItemList();
        },

        updateShowDialog(val){
            console.log('update show dialog: ' + val);
        }
    },
}

</script>
  
<style scoped></style>
  