<template>
  <v-row justify="center">
    <v-col cols="12" xl="3" lg="5" md="5" sm="8">
      <v-card class="mx-2 mt-md-16 elevation-10" :disabled="store.dialogLoader || dialogSucess || dialogFail" v-if="newUser && newUserDetails">
        <v-toolbar
          color="primary"
          title="Nova Conta"
        >      
          <v-btn icon link to="/">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-5">

          <v-card-title class="text-h6 font-weight-regular justify-space-between mb-5">
            <v-avatar
              color="primary"
              size="24"
              v-text="step"
              class="mr-2"
            ></v-avatar>
            <span>{{ currentTitle }}</span>
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">

              <v-text-field class="mb-5" clearable color="primary"
                v-model="newUser.email"
                prepend-icon="mdi-email-outline"
                id="email"
                label="Email"
                placeholder="Entre com seu email"
                maxlength="150"
                @click:clear="errorMessageMail = null"
                :error-messages="v$.newUser.email.$invalid ? ['Digite um email válido'] : []">
              </v-text-field>

              <div class="body-2 text-center mb-4" style="color : red;" v-if="errorMessageMail">
                {{ errorMessageMail }}
              </div>
              <div class="text-center">
                <v-btn class="" color="primary" variant="outlined" v-if="errorMessageMail" @click="store.dialogRecoverPwd = true">
                  esqueci minha senha
                </v-btn>
              </div>


            </v-window-item>

            <v-window-item :value="2">
                
                <v-text-field class="mb-5" clearable color="primary"
                  :disabled="v$.newUser.email.$invalid"
                  v-model="newUser.firstName"
                  prepend-icon="mdi-card-account-details-outline"
                  id="firstName"
                  label="Primeiro Nome"
                  placeholder="Seu primeiro nome"
                  maxlength="25"
                  v-on:keypress="isLetter($event)"
                  :error-messages="v$.newUser.firstName.$invalid ? ['Campo obrigatório'] : []">
                </v-text-field>

                <v-text-field class="mb-5" clearable color="primary"
                  :disabled="v$.newUser.firstName.$invalid"
                  v-model="newUser.lastName"
                  prepend-icon="mdi-card-account-details-outline"
                  id="lastName"
                  label="Sobrenome"
                  placeholder="Seu sobrenome"
                  maxlength="25"
                  v-on:keypress="isLetter($event)"
                  :error-messages="v$.newUser.lastName.$invalid ? ['Campo obrigatório'] : []">
                </v-text-field>

            </v-window-item>

            <v-window-item :value="3">
              
              <v-text-field class="mb-5" clearable color="primary"
                  :disabled="v$.newUser.lastName.$invalid"
                  v-model="newUserDetails.nascimento"
                  prepend-icon="mdi-baby-face-outline"
                  id="nascimento"
                  label="Nascimento"
                  placeholder="data de nascimento"
                  maxlength="10"
                  v-mask="'##/##/####'"
                  :error-messages="v$.newUserDetails.nascimento.$invalid ? ['Digite uma data válida'] : []">
                </v-text-field>

                <v-text-field class="mb-5" clearable color="primary"
                  :disabled="v$.newUserDetails.nascimento.$invalid"
                  v-model="newUserDetails.cpf"
                  prepend-icon="mdi-card-text-outline"
                  id="cpf"
                  label="CPF"
                  placeholder="cpf"
                  maxlength="14"
                  v-mask="'###.###.###-##'"
                  @click:clear="errorMessageMail = null"
                  :error-messages="v$.newUserDetails.cpf.$invalid ? ['Digite um CPF válido'] : []">
                </v-text-field>

                <div class="body-2 text-center mb-4" style="color : red;" v-if="errorMessageCPF">
                  {{ errorMessageCPF }}
                </div>

            </v-window-item>

            <v-window-item :value="4">
              
              <v-text-field class="mb-5" counter color="primary"
                  :disabled="v$.newUserDetails.cpf.$invalid"
                  v-model="pwd"
                  prepend-icon="mdi-key-outline"
                  id="password"
                  label="Senha"
                  placeholder="digite a senha"
                  hint="entre 6 e 20 caracteres"
                  maxlength="20"
                  :type="show1 ? 'text' : 'password'"
                  :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="show1 = !show1"
                  :error-messages="v$.pwd.$invalid ? ['Digite uma senha válida'] : []">
                </v-text-field>

                <v-text-field class="mb-5" counter color="primary"
                  :disabled="v$.pwd.$invalid"
                  v-model="confirmPwd"
                  prepend-icon="mdi-key-outline"
                  id="confirmPassword"
                  label="Confirme a senha"
                  placeholder="confirme a senha"
                  hint="entre 6 e 20 caracteres"
                  maxlength="20"
                  :type="show2 ? 'text' : 'password'"
                  :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="show2 = !show2"
                  :error-messages="v$.confirmPwd.$invalid ? ['Confirmação inválida'] : []">
                </v-text-field>

              </v-window-item>

              <v-window-item :value="5">
              
                <div class="text-body-1 text-justify">
                  Para uma experiência personalizada, diz para nós aqui:
                  <span class="font-weight-bold">Em que perfil você melhor se enquadra?</span> <br />
                  Você pode mudar seu perfil a qualquer momento, não se preocupe.
                </div>

                <v-radio-group class="mt-5 mb-5" v-model="myProfile">
                  <v-radio label="Quero contratar um serviço" value="ROLE_CLIENTE"></v-radio>
                  <v-radio label="Sou prestador de serviço" value="ROLE_PRESTADOR"></v-radio>
                </v-radio-group>

              </v-window-item>
          </v-window>

          <v-divider class="mt-5"></v-divider>

          <v-card-actions class="pt-5">
            <v-btn
              v-if="step > 1"
              variant="text"
              @click="step--"
            >
              Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="step < 5" :disabled="disableNext()"
              color="primary"
              variant="flat"
              @click="nextStep"
            >
              Próximo
            </v-btn>
            <v-btn
              v-if="step == 5" :disabled="disableNext()"
              color="primary"
              variant="flat"
              @click="createAccount"
            >
              Criar Conta
            </v-btn>
          </v-card-actions>


          
          <!-- <v-text-field class="mb-5" clearable
            v-model="newUser.email"
            prepend-icon="mdi-email-outline"
            id="email"
            label="Email"
            placeholder="Entre com seu email"
            maxlength="150"
            :error-messages="v$.newUser.email.$invalid ? ['Digite um email válido'] : []">
          </v-text-field> -->

        </v-card-text>
      </v-card>
    </v-col>


    <v-col cols="auto">
      <v-dialog persistent
        transition="dialog-bottom-transition"
        width="auto"
        v-model="dialogSucess"
      >
        <v-card>
          <v-toolbar
            color="primary" class="pr-5"
          >
            <v-toolbar-title>
              Conta criada
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon>mdi-emoticon-outline</v-icon>
          </v-toolbar>
          <v-card-text>
            <div class="body-2 pa-12 text-center">
              <p>Que bom ter você com a gente!</p>
              <p>Por favor, verifique seu email e confirme a criação da sua conta. </p>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              variant="text"
              @click="closeSuccess"
            >Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="auto">
      <v-dialog persistent
        transition="dialog-bottom-transition"
        width="auto"
        v-model="dialogFail"
        max-width="500"
      >
        <v-card>
          <v-toolbar
            color="error" class="pr-5"
          >
            <v-toolbar-title>
              Erro...
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon>mdi-emoticon-dead-outline</v-icon>
          </v-toolbar>
          <v-card-text>
            <div class="body-2 pa-12 text-justify">
              <p>Desculpe, houve um problema na criação da conta. Isso pode ter sido causado por uma inconsistência nos dados, 
                por falha nas validações ou outro problema técnico. Entre por favor em contato com nosso suporte 
                informando seus dados e o problema.
              </p>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              variant="text"
              @click="closeSuccess"
            >Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

  </v-row>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import User from '../components/entity/User'
import UserDetails from '../components/entity/UserDetails'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs } from '@vuelidate/validators'

import moment from 'moment'

export default {

  setup () {
    return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';
    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';
    },
    currentTitle () {
      switch (this.step) {
        case 1: return 'Seu email'
        case 2: return 'Nome completo'
        case 3: return 'Seus dados'
        case 4: return 'Defina a senha'
        case 5: return 'Para finalizar...'
        default: return 'Account created'
      }
    },
  },

  data: () => ({

    newUser : null,
    newUserDetails : null,

    step: 1,

    show1 : false,
    show2 : false,

    pwd : null,
    confirmPwd : null,

    myProfile : null,
    dialogSucess : false,
    dialogFail : false,

    errorMessageMail : null,
    errorMessageCPF : null,
    store,
    moment,
  }),

  mounted() {
    this.newUser = new User();
    this.newUserDetails = new UserDetails();

    //this.simpleServiceCall();
  },

  methods: {

    closeSuccess(){

      this.dialogSucess = false;
      this.$router.push("/");
    },  

    createAccount(){

      this.newUser.password = this.pwd;
      this.newUserDetails.user = this.newUser;

      let myObject = {
        user : this.newUser,
        userDetails : this.newUserDetails,
        pwd : this.pwd,
        myProfile : this.myProfile
      }
      
      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/user/account/create",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: myObject
      }).then(response => {
        store.dialogLoader = false;
        console.log('new user, response: ', response.status);
        //console.log('saved: \n' + JSON.stringify(response.data));
        if(response.data != null && response.status == 200){
          this.dialogSucess = true;
        }else{
          this.dialogFail;
        }
      })
      .catch(error => {
        console.log('error : ' + error.status);
        store.dialogLoader = false;
        
      });

    },  

    disableNext(){

      if(this.step == 1){
        return (this.v$.newUser.email.$invalid);

      }else if(this.step == 2){
        return (this.v$.newUser.firstName.$invalid || this.v$.newUser.lastName.$invalid);
      }else if(this.step == 3){
        return (this.v$.newUserDetails.nascimento.$invalid || this.v$.newUserDetails.cpf.$invalid);
      }else if(this.step == 4){
        return (this.v$.pwd.$invalid || this.v$.confirmPwd.$invalid);
      }else if(this.step == 5){
        return (this.myProfile == null);
      }
      return false;
    },

    nextStep(){

      if(this.step == 1){

        store.dialogLoader = true;
        this.axios({
          method: "post",
          url: services.serviceHost + "/user/checkemail",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          params: {
            email : this.newUser.email
          }
        }).then(response => {
          store.dialogLoader = false;
          if(response.status == 204){
            this.step += 1;
          }else if(response.status == 200){
            console.log('email invalido!');
            this.errorMessageMail = 'Este email já está cadastrado aqui.';
          }
          store.dialogLoader = false;
        })
        .catch(error => {
          console.log('check email, error : ' + error.status);
          store.dialogLoader = false;
          return true;
        });

      }else if(this.step == 2){
        this.step += 1;
        
      }
      else if(this.step == 3){
        
        store.dialogLoader = true;
        this.axios({
          method: "post",
          url: services.serviceHost + "/user/checkcpf",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          params: {
            cpf : this.newUserDetails.cpf
          }
        }).then(response => {
          store.dialogLoader = false;
          if(response.status == 204){
            this.step += 1;
          }else if(response.status == 200){
            console.log('email invalido!');
            this.errorMessageCPF = 'Este CPF já está cadastrado.';
          }
          store.dialogLoader = false;
        })
        .catch(error => {
          console.log('check email, error : ' + error.status);
          store.dialogLoader = false;
          return true;
        });

      }
      else if(this.step == 4){
        this.step += 1;
      }
      else if(this.step == 5){
        this.step += 1;
      }
    },

    isDateValid(){

      if(this.newUserDetails.nascimento && this.newUserDetails.nascimento.length == 10){
        let validDate = moment(this.newUserDetails.nascimento, "DD/MM/YYYY");
        console.log('data valida: ' + validDate.isValid());
        return validDate.isValid();
      }
      return false;
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations() {
    return {

      pwd: {
        required,
        min: minLength(6),
        max: maxLength(20)
      },
      confirmPwd: {
        required,
        min: minLength(6),
        max: maxLength(20),
        sameAs: sameAs(this.pwd),
      },

      newUser: {
        email: {
          required, email,
          max: maxLength(150)
        },
        firstName: {
          required,
          min: minLength(2),
          max: maxLength(25),
        },
        lastName: {
          required,
          min: minLength(2),
          max: maxLength(25),
        },
      },
      newUserDetails: {
        nascimento: {
          required,
          min: minLength(10),
          max: maxLength(10),
          minValue : value => {
            if(value && value.length == 10){
              let validDate = moment(value, "DD/MM/YYYY");
              if(validDate.isValid()){
                let hoje = moment();
                let myDiff = hoje.diff(validDate, 'years');
                if(myDiff >= 18 && myDiff <= 90){
                  return true;
                }else{
                  return false;
                }
              }else{
                return false;
              }
            }
          }
        },
        cpf: {
          required,
          min: minLength(14),
          max: maxLength(14),
          minValue : value => {
            if(value != null && value.length == 14){
              return this.testaCPF(value.replaceAll('.', '').replace('-', ''));
            }
          }
        },
      },
    }
  },

}

</script>

<style scoped>


</style>
