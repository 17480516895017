<template>
  <v-row>
      <v-col cols="12" xl="3" lg="4" md="4" sm="8">
          <v-dialog 
              transition="dialog-bottom-transition"
              :scrim="true"
              max-width="600"
              v-model="localShowDialog"
              :fullscreen="isSmallScreen"
              persistent
              >
              <v-card :disabled="store.dialogLoader" v-if="selectedItem">
                  <v-toolbar
                  color="primary"
                  :title="selectedItem.servico.nomeServico"
                  >
                      <v-btn icon @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-toolbar>
                  <v-card-text class="pa-4 pt-4">
                      
                      <v-row>

                        <v-col cols="12" class="pt-4 pb-1">
                          <div class="">Criado em {{ selectedItem.dtCreated }} hs</div>
                        </v-col>

                        <v-col cols="12" class="pt-1 pb-1">
                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="12" class="pb-0 pt-4">
                          {{ selectedItem.endereco.logradouro }}
                        </v-col>
                        <v-col cols="12" class="pb-0 pt-0">
                          {{ selectedItem.endereco.bairro }}
                        </v-col>
                        <v-col cols="12" class="pb-0 pt-0">
                          {{ selectedItem.endereco.localidade }}, {{ selectedItem.endereco.uf }}
                        </v-col>

                        <v-col cols="12" class="">
                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="12" class="pb-0 pt-0 text-justified text-body-1">
                          {{ selectedItem.description }}
                        </v-col>

                        <v-col cols="12" class="">
                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="12" class="pb-0 pt-0 text-justified font-weight-black" v-if="selectedItem.myPrice">
                          Valor oferecido R$ {{ formataMoedaBRL(selectedItem.originalPrice) }}
                        </v-col>

                        <v-col cols="12" class="pb-0 pt-0 text-justified" v-else>
                          Proponha seu valor.
                        </v-col>

                        <v-col cols="12" class="">
                          <v-divider></v-divider>
                        </v-col>

                      </v-row>

                  </v-card-text>

                  
                  <v-card-actions class="pa-5" v-if="store.userLogged && selectedItem.ownerId == store.userLogged.id">
                    <v-spacer v-if="!isSmallScreen"></v-spacer>
                    <v-btn variant="outlined" color="primary"
                      class="" text width="100%">
                      Você criou esse Pedido
                    </v-btn>  
                  </v-card-actions>
                  <v-card-actions class="pa-5" v-else-if="store.myPropostasIDs && store.myPropostasIDs.includes(selectedItem.id) && store.userLogged && selectedItem.ownerId != store.userLogged.id">
                    <v-spacer v-if="!isSmallScreen"></v-spacer>
                    <v-btn variant="outlined" color="primary"
                      class="" text width="100%">
                      Você já se candidatou
                    </v-btn>  
                  </v-card-actions>
                  <!-- <v-card-actions class="pa-5" v-if="(!store.myPropostasIDs || !store.myPropostasIDs.includes(selectedItem.id)) && store.userLogged && (selectedItem.ownerId != store.userLogged.id)"> -->
                  <v-card-actions class="pa-5" v-else>
                    <v-spacer v-if="!isSmallScreen"></v-spacer>
                    <v-btn variant="outlined" color="primary" v-if="selectedItem.myPrice"
                      @click="enviaProposta()"
                      class="" text width="100%">
                      Me candidatar
                    </v-btn> 
                    <v-btn variant="outlined" color="primary" v-else
                      @click="enviaProposta()"
                      class="" text width="100%">
                      Fazer Proposta
                    </v-btn>  
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-col>
  </v-row>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'
  
  export default {

    setup () {
        return { v$: useVuelidate() }
    },

    props: {
        titleMessage : String,
        itemId : Number,
        itemPedido : Object,
        showDialog : Boolean,
    },
  
    mixins: [
      myMixin,
    ],
  
    beforeMount (){
      //this.selectedItem = null;
      this.isSmallScreen = this.$vuetify.display.mobile;

      if(this.itemId){

        //store.dialogLoader = true;
        const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .post(services.serviceHost + "/pedido/load/" + this.itemId, {}, config)
              .then(response => {
              //console.log(response.data);
              this.selectedItem = response.data;
              //store.dialogLoader = false;
          });
        }else{
          this.selectedItem = this.itemPedido;
        }

        this.loadMyPedidosComPropostaID();
    },

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,
    }),
  
    methods: {

      enviaProposta(){

        //console.log('salvando pedido : \n' + JSON.stringify(this.pedido));
        store.pedidoProposal = this.selectedItem;
        store.redirectPage = '/user/pedido/proposta';
        this.pedidoProposta();
      },
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('pedido-detalhe-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  