<template>
  <v-row class="px-2">
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5 fill-height">
        <v-btn width="100%" height="100%" color="#34495E ">
          <v-icon size="40" color="white">mdi-account-hard-hat</v-icon>
        </v-btn>
      </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
        :src="imageList[1].url"
        class="align-start"
        cover
      ></v-img>
    </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
        :src="imageList[2].url"
        class="align-start"
        cover
      ></v-img>
      </v-card>
    </v-col>
    

    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
          :src="imageList[0].url"
          class="align-start"
          cover
        ></v-img>
      </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5 fill-height">
        <v-btn width="100%" height="100%" color="#34495E ">
          <v-icon size="40" color="white">mdi-broom</v-icon>
        </v-btn>
      </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
        :src="imageList[3].url"
        class="align-start"
        cover
      ></v-img>
      </v-card>
    </v-col>

    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
        :src="imageList[4].url"
        class="align-start"
        cover
      ></v-img>
      </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5">
        <v-img
        :src="imageList[5].url"
        class="align-start"
        cover
      ></v-img>
      </v-card>
    </v-col>
    <v-col cols="4" class="d-block d-sm-none pa-1">
      <v-card class="elevation-5 fill-height">
        <v-btn width="100%" height="100%" color="#34495E ">
          <v-icon size="40" color="white">mdi-account-tie</v-icon>
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen: false,

    imageList: [
      {
          id: 1,
          text: "TUDO O QUE VOCÊ PRECISA, AQUI",
          url: require("@/assets/img/home/nail-2.jpeg"),
      },
      {
          id: 2,
          text: "PAGUE APENAS APÓS O SERVIÇO CONCLUÍDO",
          url: require("@/assets/img/home/cook-2.jpeg"),
      },
      {
          id: 3,
          text: "OS MELHORES PROFISSIONAIS",
          url: require("@/assets/img/home/pintor-2.png"),
      },
      {
          id: 4,
          text: "SERVIÇOS PERSONALIZADOS PARA VOCÊ",
          url: require("@/assets/img/home/garden-2.png"),
      },
      {
          id: 5,
          text: "EM QUALQUER LUGAR",
          url: require("@/assets/img/home/pintor-3.png"),
      },
      {
          id: 6,
          text: "PRATICIDADE",
          url: require("@/assets/img/home/clean-floor.png"),
      },
      // {
      //     id: 7,
      //     text: "AGILIDADE",
      //     url: require("@/assets/img/home/car-wash-2.png"),
      // },
      // {
      //     id: 8,
      //     text: "CONTRATAÇÃO SIMPLES E RÁPIDA",
      //     url: require("@/assets/img/home/barbecue-1.jpeg"),
      // },
      // {
      //     id: 9,
      //     text: "COMECE AGORA!",
      //     url: require("@/assets/img/home/notebook-1.jpeg"),
      // },
    ]
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.simpleServiceCall();
  },

  methods: {

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
