<template>
    <v-card class="pa-0 mb-4 elevation-10" color="grey">
        <div ref="map" class="map"></div>
    </v-card>
</template>
  
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import { myMixin } from '@/mixin';

export default {

    props: {

        itemList: Array
    },

    mixins: [
        myMixin,
    ],

    data() {
        return {
            map: null,
            marker: null,
        };
    },

    mounted() {

        this.$nextTick(() => {

            delete L.Icon.Default.prototype._getIconUrl;

            L.Icon.Default.mergeOptions({
                iconRetinaUrl: markerIcon2x,
                iconUrl: markerIcon,
                shadowUrl: markerShadow,
            });

            this.map = L.map(this.$refs.map).setView([-22.92392570, -43.23224860], 9, {animate : false});
            this.map.setMinZoom(9);
            this.map.setMaxZoom(16);
            setTimeout(() => {
                this.map.zoomIn();
            }, 1000);

            //this.map.zoomIn({ animate: false });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
            this.marker = L.marker([-22.92392570, -43.23224860]).addTo(this.map);

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    this.map.setView([latitude, longitude], 11);
                    this.marker.setLatLng([latitude, longitude]);
                },
                (error) => {
                    console.log(error.message);
                }
            );

            //adicionando ao mapa as marcas dos pedidos:
            if(this.itemList){
                this.itemList.forEach(marker => {
                    if(marker.longitude && marker.latitude){

                        const tooltip = L.tooltip({permanent: true, direction: 'top', className: 'tooltip-class'}).setContent(marker.tag);
                        const popupContent = `
                        <div class="my-popup">
                            <h2>${marker.servico.nomeServico}</h2>
                            <p>
                                ${marker.endereco.bairro}<br/>
                                ${marker.endereco.logradouro}<br/>
                                ${marker.originalPrice ? 'Valor oferecido R$ ' + this.formataMoedaBRL(marker.originalPrice) : 'Envie seu orçamento'}<br/>
                            </p>
                        </div>
                        `;
                        const leafletMarker = L.marker([marker.latitude, marker.longitude], {title: (marker.servico.nomeServico)}).addTo(this.map);
                        leafletMarker.bindTooltip(tooltip)
                        leafletMarker.bindPopup(popupContent, {
                        className: 'popup-class',
                        minWidth: 200
                        });
                        this.map.on('popupopen', function(){
                            console.log('abrindo pedido ' + marker.id);
                            //leafletMarker.setView([marker.latitude, marker.longitude], 13);
                        });

                        //leafletMarker.getPopup().on('open', this.openPedidoDetalhe(marker.id));
                        // document.getElementById('selectedItem-' + marker.id).addEventListener('click', () => {
                        //     console.log('Button clicked!');
                        // });

                        //console.log('btn : ' + leafletMarker.getPopup().querySelector('.tooltip-button'));
                        //.bindPopup(marker.servico.nomeServico);
                    }
                });
            }
        });
    },

    methods : {

        openPedidoDetalhe(marker){
            //var a = document.querySelector('input[name=Item-'+this.options.divId+']');
            console.log('abrindo detalhe do pedido: ' + marker.id);
        }
    },
};
</script>

<style>
.map {
    height: 400px;
}
.tooltip-class {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 5px;
  border-radius: 5px;
}
.tooltip-button {
  background-color: #666;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  width: 100%;
}
.my-popup-class {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
}
.my-popup h2 {
  font-size: 0.9rem;
  margin: 0 0 10px;
}
.my-popup p {
  font-size: 0.7rem;
  margin: 0;
}
</style>
  