<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="6" md="4" sm="12">
      <v-card v-if="store.pedidoSaving" :disabled="store.dialogLoader">
        <v-toolbar
          color="primary"
          title="Confirme os dados"
        >
          <v-btn icon @click="exitWithoutSave()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
        <v-card-text class="">
          <v-row>
            <v-col cols="12" class="text-body-1 text-center">
              {{ store.pedidoSaving.servico.nomeServico }}
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-body-1">
              <card-endereco-info title="Local do Serviço" v-bind:current-endereco="store.pedidoSaving.endereco"
                :key=" Math.random()" :show-delete="false"
                />
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-body-2 text-center" v-if="!store.pedidoSaving.myPrice">
              O prestador oferecerá o preço.
            </v-col>
            <v-col cols="12" class="text-body-2 font-weight-bold text-center" v-else>
              Você paga: R$ {{ formataMoedaBRL(store.pedidoSaving.originalPrice) }}
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-body-2 text-justified">
              {{ store.pedidoSaving.description }}
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-4" width="100%" variant="outlined"
            @click="savePedido()">
            Salvar Pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import CardEnderecoInfo from '@/components/common/CardEnderecoInfo.vue';


export default {

  components : {
    CardEnderecoInfo,
  },

  mixins: [
    myMixin,
  ],

  computed : {
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,
  }),

  mounted() {

    if(store.pedidoSaving){
      //this.simpleServiceCall();
      this.isSmallScreen = this.$vuetify.display.mobile;

      //console.log(JSON.stringify(store.pedidoSaving));
    }else{
      this.exitWithoutSave();
    }
  },

  methods: {

    exitWithoutSave(){
      store.pedidoSaving = null;
      store.redirectPage = null;
      this.$router.push('/');
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
