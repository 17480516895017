<template>
    <div>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="4" sm="8">
                <v-dialog min-width="350" @click:outside="closeModal"
                    transition="dialog-bottom-transition"
                    :scrim="true"
                    max-width="400"
                    :fullscreen="isSmallScreen"
                    v-model="localShowDialog"
                    >
                    <v-card :disabled="store.dialogLoader" v-if="selectedItem">
                        <v-toolbar 
                        color="primary"
                        >
                            <v-toolbar-title class="">
                                {{ titleMessage }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeModal">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="body-1 pa-6 pt-10">
                            
                          <v-text-field class="mb-5"
                              v-model="selectedItem.nomeServico"
                              id="nomeServico"
                              label="Nome do Serviço"
                              maxlength="30"
                              clearable
                              counter
                              on-update:focused="true"
                              :error-messages="v$.selectedItem.nomeServico.$invalid ? ['Digite um nome válido'] : []">
                          </v-text-field>

                          <v-select
                            v-model="selectedItem.categoriaList"
                            chips
                            label="Categorias (opcional)"
                            :items="categoriaList"
                            item-title="nomeCategoria"
                            multiple
                            return-object
                          ></v-select>

                        </v-card-text>

                        <v-card-actions class="pa-5 pt-0">
                        <v-spacer v-if="!isSmallScreen"></v-spacer>
                        <v-btn color="primary" class="px-4" :disabled="v$.selectedItem.nomeServico.$invalid"
                            :width="isSmallScreen ? '100%' : ''"
                            @click="saveItem"
                            arge variant="outlined">
                            Salvar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  import Servico from '../entity/Servico';
  
  export default {

    setup () {
        return { v$: useVuelidate() }
    },

    props: {
        titleMessage : String,
        itemId : Number,
        showDialog : Boolean,
    },
  
    mixins: [
      myMixin,
    ],

    beforeMount (){
      //this.selectedItem = null;

      this.isSmallScreen = this.$vuetify.display.mobile;

      this.loadCategoriaList();
      if(this.itemId){

        store.dialogLoader = true;
        const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .post(services.serviceHost + "/servico/load/" + this.itemId, {}, config)
              .then(response => {
              //console.log(response.data);
              this.selectedItem = response.data;
              store.dialogLoader = false;
          });
        }else{
          this.selectedItem = new Servico();
        }
    },

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    data: () => ({
        store,
        nomeServico : null,
        selectedItem : null,

        categoriaList : [],

        isSmallScreen : false,
    }),
  
    methods: {

      loadCategoriaList (){
        //store.dialogLoader = true;
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .post(services.serviceHost + "/categoria/enabled", {}, config)
            .then(response => {
            //console.log(response.data);
            this.categoriaList = response.data;
            store.dialogLoader = false;
        })
        .catch(error => {
            console.log(error.message);
            store.dialogLoader = false;
            this.loading = false;
            store.showDialogError = true;
            this.logout();
        });
      },  
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('item-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeServico : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  