<template>
  <v-row justify="center">
    <v-col cols="12" xl="3" lg="4" md="5" sm="8">
      <v-card class="mx-2 mt-md-16 elevation-10" :disabled="store.dialogLoader" v-if="codeVerified">
        <v-toolbar
          color="primary" class="pr-5"
        >
          <v-toolbar-title>
            Redefinir Senha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text class="px-5 pa-10" v-if="!codeValid && codeVerified">
          <div class="body-2 font-weight-bold text-center">
            O link é inválido, já foi utilizado ou expirou. 
          </div>
        </v-card-text>
        <v-card-text class="px-5 pa-10" v-if="codeValid && codeVerified">

          <v-text-field class="mb-5" counter color="primary"
            v-model="newPwd"
            prepend-icon="mdi-key-outline"
            id="password"
            label="Nova Senha"
            placeholder="digite a senha"
            hint="entre 6 e 20 caracteres"
            maxlength="20"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show1 = !show1"
            :error-messages="v$.newPwd.$invalid ? ['Digite uma senha válida'] : []">
          </v-text-field>

          <v-text-field class="mb-5" counter color="primary"
            :disabled="v$.newPwd.$invalid"
            v-model="confirmNewPwd"
            prepend-icon="mdi-key-outline"
            id="confirmPassword"
            label="Confirme a senha"
            placeholder="confirme a senha"
            hint="entre 6 e 20 caracteres"
            maxlength="20"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show2 = !show2"
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirmação inválida'] : []">
          </v-text-field>
          
        </v-card-text>
        <v-card-actions class="pa-5" v-if="codeValid && codeVerified">
          <v-spacer></v-spacer>
          <v-btn :disabled="v$.confirmNewPwd.$invalid || v$.newPwd.$invalid"
            variant="text" @click="saveNewPwd"
          >Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog
      transition="dialog-bottom-transition"
      width="auto"
      v-model="dialogErrorPwdUpdate"
    >
      <v-card>
        <v-toolbar
          color="error"
          title="Algo deu errado..."
        ></v-toolbar>
        <v-card-text>
          <div class="body-1 pa-6 text-center">
            Ocorreu um erro ao atualizar sua senha. Entre em contato com o nosso suporte.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogErrorPwdUpdate = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      width="auto" persistent
      v-model="dialogSuccessPwdUpdate"
    >
      <v-card>
        <v-toolbar
          color="primary"
          title="Sucesso"
        ></v-toolbar>
        <v-card-text>
          <div class="body-1 pa-6 text-center">
            Senha atualizada com sucesso
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeSuccessDialog()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, sameAs } from '@vuelidate/validators'

import { aesUtil } from '../aes'

export default {

  setup () {
    return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  data: () => ({
      
    errorMessage : null,
    store,

    codeValid : false,
    codeVerified : false,

    newPwd : null,
    confirmNewPwd : null,

    show1 : false,
    show2 : false,

    dialogErrorPwdUpdate : false,
    dialogSuccessPwdUpdate : false,
  }),

  mounted() {
    this.validateHash();
  },

  methods: {

    validateHash(){

      store.dialogLoader = true;
      
      this.axios({
        method: "post",
        url: services.serviceHost + "/recover/checkcode",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          code : this.$route.params.accessCode
        }
      }).then(response => {
        this.codeVerified = true;

        store.dialogLoader = false;
        if(response.status == 200){
          //console.log('code is valid');
          this.codeValid = true;
        }else if(response.status == 204){
          //console.log('code is invalid');
        }
        store.dialogLoader = false;
      })
      .catch(error => {
        console.log('check code, error : ' + error.status);
        store.dialogLoader = false;
        return true;
      });
    },

    saveNewPwd(){

      store.dialogLoader = true;
      
      this.axios({
        method: "post",
        url: services.serviceHost + "/recover/savenewpwd",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: {
          newPwd : aesUtil.encrypt(services.myphrase, this.newPwd),
          accessCode : this.$route.params.accessCode,
        }
      }).then(response => {

        store.dialogLoader = false;
        if(response.data != null){
          this.dialogSuccessPwdUpdate = true;
        }else {
          this.dialogErrorPwdUpdate = true;
        }
      })
      .catch(error => {
        console.log('check code, error : ' + error.status);
        this.dialogErrorPwdUpdate = true;
        //console.log(error);
        store.dialogLoader = false;
      });

    },

    closeSuccessDialog(){
      this.$router.push("/login");
    }

  },

  validations() {
    return {

      newPwd: {
        required,
        min: minLength(6),
        max: maxLength(20)
      },
      confirmNewPwd: {
        required,
        min: minLength(6),
        max: maxLength(20),
        sameAs: sameAs(this.newPwd),
      },
    }
  }

}

</script>

<style scoped>


</style>
