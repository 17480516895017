import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSmoothScroll from 'vue3-smooth-scroll'

import Moment from 'moment'
//import MomentTimezone from 'moment'

//url dp plugin:
//https://vuejs-tips.github.io/vue-the-mask/
import VueTheMask from 'vue-the-mask'

//import do prime:
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(VueAxios, axios)
  .use(PrimeVue)
  .use(VueTheMask)
  .use(Moment)
  .use(VueSmoothScroll, {
    duration: 2000,
    updateHistory: false,
  })

  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('InputText', InputText)

  .mount('#app')
