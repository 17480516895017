<template>
  <div>
      <v-row>
          <v-col cols="12" xl="3" lg="4" md="4" sm="8">
              <v-dialog 
                  transition="dialog-bottom-transition"
                  :scrim="true"
                  max-width="650"
                  v-model="localShowDialog"
                  :persistent="!isSmallScreen"
                  :fullscreen="isSmallScreen"
                  >
                  <v-card :disabled="store.dialogLoader" v-if="selectedItem">
                      <v-toolbar
                      color="primary"
                      >
                          <v-toolbar-title class="">
                              {{ titleMessage }}
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="closeModal">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text class="body-1 pa-6 pt-10">
                          
                          <v-row>

                            <v-col cols="12" class="pb-0">
                              <v-select label="Tipo de Endereço"
                                v-model="selectedItem.tipo" :items="tipoEnderecoList">
                              </v-select>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 8 : 8">
                              <v-text-field class="" clearable color="primary"
                                @click:clear="resetFields()"
                                v-model="selectedItem.cep"
                                prepend-inner-icon="mdi-map"
                                id="cep"
                                label="CEP"
                                hint="CEP do local do serviço"
                                placeholder="local do serviço"
                                maxlength="9"
                                v-mask="'#####-###'"
                                type="numberic"
                                @keyup="loadEndereco()"
                                :error-messages="v$.selectedItem.cep.$invalid ? ['Digite o CEP'] : []">
                              </v-text-field>
                            </v-col>

                            
                            <v-col :cols="isSmallScreen ? 4 : 4">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.cep.$invalid"
                                v-model="selectedItem.uf"
                                prepend-inner-icon="mdi-map-marker-radius-outline"
                                id="uf"
                                label="UF"
                                maxlength="2"
                                readonly
                                :error-messages="v$.selectedItem.uf.$invalid ? ['Estado'] : []">
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 12 : 6">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.uf.$invalid"
                                v-model="selectedItem.localidade"
                                prepend-inner-icon="mdi-city"
                                id="cidade"
                                label="Cidade"
                                maxlength="200"
                                readonly
                                :error-messages="v$.selectedItem.localidade.$invalid ? ['Digite a cidade'] : []">
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 12 : 6">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.localidade.$invalid"
                                v-model="selectedItem.bairro"
                                prepend-inner-icon="mdi-home-city-outline"
                                id="bairro"
                                label="Bairro"
                                maxlength="200"
                                :readonly="!allowEditAddress"
                                :error-messages="v$.selectedItem.bairro.$invalid ? ['Digite a bairro'] : []">
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 12 : 8">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.localidade.$invalid"
                                v-model="selectedItem.logradouro"
                                prepend-inner-icon="mdi-home-outline"
                                id="logradouro"
                                label="Logradouro"
                                maxlength="200"
                                :readonly="!allowEditAddress"
                                :error-messages="v$.selectedItem.logradouro.$invalid ? ['Digite o logradouro'] : []">
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 5 : 4">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.localidade.$invalid"
                                v-model="selectedItem.numero"
                                prepend-inner-icon="mdi-door"
                                id="numero"
                                type="numberic"
                                label="Numero"
                                maxlength="10"
                                :error-messages="v$.selectedItem.numero.$invalid ? ['Digite o numero'] : []">
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 7 : 6">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.localidade.$invalid"
                                v-model="selectedItem.complemento"
                                prepend-inner-icon="mdi-door"
                                id="complemento"
                                label="Complemento"
                                maxlength="20"
                                counter>
                              </v-text-field>
                            </v-col>

                            <v-col :cols="isSmallScreen ? 12 : 6">
                              <v-text-field class="" color="primary"
                                :disabled="v$.selectedItem.localidade.$invalid"
                                v-model="selectedItem.pontoReferencia"
                                prepend-inner-icon="mdi-directions"
                                id="referência"
                                label="Ponto de Referência"
                                maxlength="60"
                                counter>
                              </v-text-field>
                            </v-col>

                          </v-row>

                      </v-card-text>

                      <v-card-actions class="pa-5 pt-0">
                      <v-spacer></v-spacer>
                      <v-btn color="primary" class="px-4" :disabled="v$.selectedItem.$invalid"
                          @click="saveItem" :width="isSmallScreen ? '100%': ''" 
                          arge variant="outlined">
                          Salvar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-col>
      </v-row>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import { required, minLength, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import Endereco from '../entity/Endereco';

//import { useDisplay } from 'vuetify'


export default {

  setup () {

    return { 
      v$: useVuelidate(),
    }
  },

  props: {
      titleMessage : String,
      itemId : Number,
      showDialog : Boolean,
  },

  mixins: [
    myMixin,
  ],

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;
  },

  mounted (){

    //this.selectedItem = null;
    if(this.itemId){

    store.dialogLoader = true;
    const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
          .post(services.serviceHost + "/categoria/load/" + this.itemId, {}, config)
          .then(response => {
          //console.log(response.data);
          this.selectedItem = response.data;
          store.dialogLoader = false;
      });
    }else{
      this.selectedItem = new Endereco();
    }
  },

  computed : {
    localShowDialog : {
      get: function () {
          return this.showDialog;
      },
      set: function (val) {
          this.$emit('update-show-dialog', val);
      }
    }
  },

  data: () => ({
      store,
      nomeCategoria : null,
      selectedItem : null,

      isSmallScreen: false,

      allowEditAddress : false,

      tipoEnderecoList : ['Residencial', 'Comercial'],
  }),

  methods: {

    resetFields(){
      this.selectedItem.uf = null;
      this.selectedItem.localidade = null;
      this.selectedItem.bairro = null;
      this.selectedItem.logradouro = null;
      this.selectedItem.numero = null;
      this.selectedItem.complemento = null;
      this.selectedItem.pontoReferencia = null;
      this.allowEditAddress = false;
    },

    loadEndereco(){

      this.resetFields();
      if(this.selectedItem.cep && this.selectedItem.cep.length == 9){

        store.dialogLoader = true;
        //https://opencep.com/v1/20531005.json
        this.axios
          .get('https://opencep.com/v1/' + this.selectedItem.cep.replaceAll('-', '') + '.json', {}, {})
          .then(response => {
          //console.log('resultado do CEP : \n' + JSON.stringify(response.data));
          store.dialogLoader = false;
          if(response.data){
            this.selectedItem = response.data;
            this.selectedItem.tipo = this.tipoEnderecoList[0];
            if(this.selectedItem.uf && !this.selectedItem.logradouro){
              this.allowEditAddress = true;
            }
          }
        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
        });
      }
    },

    saveItem(){

      this.$emit('item-modal-endereco-save', this.selectedItem);
      this.selectedItem = null;
    },

    closeModal(){
      this.$emit('item-modal-close');
    },

  },

  validations() {
      return {

        selectedItem: {
          cep: {
            required,
            min: minLength(9),
            max: maxLength(9),
          },
          uf : {
            required,
            min: minLength(2),
            max: maxLength(2),
          },
          logradouro : {
            required,
            min: minLength(2),
            max: maxLength(200),
          },
          bairro : {
            required,
            min: minLength(2),
            max: maxLength(100),
          },
          localidade : {
            required,
            min: minLength(2),
            max: maxLength(100),
          },
          numero : {
            required,
            max: maxLength(10),
          }
        }
      }
  }

}

</script>

<style scoped>


</style>
