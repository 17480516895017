<template>
  <div>
    <div id="topoLista"></div>

    <!-- <v-card :disabled="store.dialogLoader" v-if="pedidoList && isSmallScreen" class="elevation-10">
      <v-toolbar color="primary">

        <v-toolbar-title>Você abriu {{ store.myPedidosCount }} Pedidos</v-toolbar-title>
        <v-btn icon large link to="/" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
  
        <v-text-field v-model="search"
          append-inner-icon="mdi-magnify"
          variant="outlined"
          label="Filtrar"
          density="compact"
          clearable
          ></v-text-field>
    
      </v-card-text>
    </v-card> -->

    <div v-if="pedidoList && isSmallScreen">
      <box-pedido-mobile-list :item="item" v-for="item in pedidoList" :key="item.id"
        @list-propostas-pedido="listaPropostasDoPedido($event)" @iniciar-servico="iniciarServico($event)"
        @finish-job="finishJob($event)"
        @deletar-pedido="deletePedido($event)" @abrir-pedido-detalhe="openPedidoDetalhe($event)"></box-pedido-mobile-list>
    </div>

    <v-card :disabled="store.dialogLoader" v-if="pedidoList && !isSmallScreen">
      <v-toolbar color="primary">

        <v-toolbar-title>Você abriu {{ store.myPedidosCount }} Pedidos</v-toolbar-title>
        <v-btn icon large link to="/" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-card class="pa-2 mb-4">
          <v-text-field v-model="search"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
            ></v-text-field>
        </v-card>
        
        <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="pedidoList"
          class="elevation-1"
          :search="search"
        >
        <template v-slot:[`item.servico`]="{ item }">
          {{ item.raw.servico.nomeServico }}
        </template>

        <template v-slot:[`item.originalPrice`]="{ item }">
          <span v-if="item.raw.originalPrice">{{'R$ ' + formataMoedaBRL(item.raw.originalPrice)}}</span>
          <span v-else-if="!item.raw.originalPrice && item.raw.acceptedProposta && item.raw.acceptedProposta.offeredPrice">{{'R$ ' + formataMoedaBRL(item.raw.acceptedProposta.offeredPrice)}}</span>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.endereco`]="{ item }">
          {{ item.raw.endereco.logradouro }}
        </template>

        <template v-slot:[`item.propostaList`]="{ item }">
          <span v-if="item.raw.propostaList.length == 0">{{ item.raw.propostaList.length }}</span>
          <v-btn text v-if="item.raw.propostaList.length > 0" size="small"
            @click="listaPropostasDoPedido(item.raw)"  
            color="primary" variant="outlined" prepend-icon="mdi-magnify">
            {{ item.raw.propostaList.length }}
          </v-btn>
        </template>

        <template v-slot:[`item.acceptedProposta`]="{ item }">
          {{ (item.raw.acceptedProposta ? item.raw.acceptedProposta.prestadorNome : 'em aberto') }}
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-btn icon variant="outlined" x-small size="x-small" color="primary" @click="openDialogPedido(item.raw)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>

        <!-- <template v-slot:[`item.enabled`]="{ item }">
          <v-switch v-model="item.raw.enabled" color="primary" :disabled="item.raw.acceptedProposta"
            @change="changeStatus(item.raw.id)"></v-switch>
        </template> -->
        
        
        </v-data-table>

      </v-card-text>

    </v-card>

    <modal-pedido-detail-owner v-if="selectedPedido" :key="selectedPedido.id" @pedido-detalhe-modal-close="closeDetalheModal()"
      :item-id="null" :item-pedido="selectedPedido" :show-dialog="showDialogPedidoDetalhe" />

    <modal-list-propostas-do-pedido v-if="selectedPedido" :key="selectedPedido.id" :show-dialog="showDialogListPropostasDoPedido"
      :item="selectedPedido" @modal-list-propostas-pedido-close="closeModalListPropostasDoPedido()" 
      @pedido-proposta-aceitar="aceitarProposta($event)"/>

    <!-- <v-layout-item model-value position="bottom" class="text-end fixed pr-8" size="88" v-if="position[1] > 80 && isSmallScreen" >
      <v-btn icon color="primary" v-if="position[1] > 80" transition="fade-transition" class="elevation-6"
        @click="scrollToTheTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-layout-item> -->

    <v-dialog v-model="showDialogPropostaSuccess" persistent transition="dialog-top-transition"
      max-width="400" :fullscreen="isSmallScreen" :disabled="store.dialogLoader">
      <v-card :disabled="dialogLoader">
        <v-toolbar color="primary">
          <v-toolbar-title>
            Parabéns!
          </v-toolbar-title>
          <v-btn icon large @click="closeDialogPropostaSuccess()" color="white">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-center">
          <div>
            Estamos enviando os dados do prestador para o seu e-mail. 
          </div>
          <div class="mt-4">
            Você também pode visualizar os dados das suas propostas aceitas a qualquer momento, no menu 
            <span class="font-weight-black">"Meus Pedidos"</span>.
          </div>
          <div class="mt-6">
            <v-icon size="50" color="primary">mdi-handshake-outline</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="px-4" width="100%"
            large variant="outlined" @click="closeDialogPropostaSuccess()"> 
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expand-x-transition>
      <div class="floating-button text-center" v-if="position[1] > 80 && isSmallScreen">
        <v-btn icon color="grey-darken-2" v-if="position[1] > 80" class="elevation-6" 
          @click="scrollToTheTop">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>

  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import windowScrollPosition from '@/mixins/window-scroll-position';
import services from '../../ws/services';
import { store } from '../../store';

import { VDataTable } from 'vuetify/labs/VDataTable';

import ModalPedidoDetailOwner from '@/components/modal/ModalPedidoDetailOwner.vue';
import ModalListPropostasDoPedido from '@/components/modal/ModalListPropostasDoPedido.vue';

import BoxPedidoMobileList from '@/components/common/BoxPedidoMobileList.vue';

export default {

  components : {
    VDataTable,
    ModalPedidoDetailOwner,
    BoxPedidoMobileList,
    ModalListPropostasDoPedido,
  },

  mixins: [
    myMixin,
    windowScrollPosition('position'),
  ],

  computed : {

  },

  setup() {
    
  },

  data: () => ({

    headers : [
      { title: 'Serviço', align: 'start', key: 'servico', sortable: true},
      { title: 'Preço', align: 'start', key: 'originalPrice', sortable: true},
      { title: 'Local', align: 'start', key: 'endereco', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Propostas', align: 'center', key: 'propostaList', sortable: true},
      { title: 'Prestador', align: 'center', key: 'acceptedProposta', sortable: false},
      { title: 'Detalhe', align: 'center', key: 'id', sortable: false},
      //{ title: 'Ativo', align: 'center', key: 'enabled', sortable: false},

    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    pedidoList : null,

    showDialogPedidoDetalhe : false,
    showDialogListPropostasDoPedido : false,
    selectedPedido : null,
    showDialogPropostaSuccess : false,

    search :  null,

  }),

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;
    console.log('pedido status: ' + this.$route.params.pedidoStatus);

  },  

  mounted() {
    this.loadPedidos();
    this.loadBasicGlobalData();
  },

  methods: {

    async finishJob(myPedido){

      console.log('finalizando job do pedido: ' + myPedido.id + ', rating: ' + myPedido.rating);
      let myData = {
        pedidoId : myPedido.id,
        score : myPedido.rating
      }
      
      const changedPedido = await this.finishJobPedido(myData);
      console.log('finishJobPedido pedido, new status : ' + changedPedido.pedidoStatus);

      const index = store.pedidosEmExecucaoList.findIndex(item => item.id === changedPedido.id);
      store.pedidosEmExecucaoList.splice(index, 1, changedPedido);
      this.loadPedidos();
    },

    async iniciarServico(myPedido){

      try{
        console.log('iniciando servico do pedido ' + myPedido.id);
        const changedPedido = await this.startJobPedido(myPedido);
        console.log('changed pedido, new status : ' + changedPedido.pedidoStatus);
        //console.log('VUE INSTANCE : ' + this.$root);

        const index = store.pedidosEmAndamentoList.findIndex(item => item.id === changedPedido.id);
        //this.pedidoList.splice(index, 1, changedPedido);
        store.pedidosEmAndamentoList.splice(index, 1, changedPedido);
        this.loadPedidos();
        //await this.loadMyPedidosAceitos();

      }catch(error){
        console.log('******* PROBLEMA COM START DO JOB: \n' + error);
      }
    },

    closeDialogPropostaSuccess(){
      this.showDialogPropostaSuccess = false;
      this.loadPedidos();
    },

    aceitarProposta(myProposta){
      console.log('aceitando proposta : ' + myProposta.id);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/accept/" + myProposta.id + '/' + myProposta.pedidoId, {}, config)
        .then(response => {
          console.log('proposta salva : ' + response.status);
          store.dialogLoader = false;
          this.closeModalListPropostasDoPedido();
          this.showDialogPropostaSuccess = true;

          //verificamos se ficou tudo ok:
          if(response.status == 200){
            this.showDialogPropostaSuccess = true;
          }else{
            store.showDialogError = true;
          }

      })
      .catch(error => {
        console.log('proposta salva : ' + error.status);
        store.dialogLoader = false;
        this.closeModalListPropostasDoPedido();
        store.showDialogError = true;
      });
    },

    listaPropostasDoPedido(myItem){
      console.log('abrindo propostas do pedido : ' + myItem.id);
      this.selectedPedido = myItem;
      this.showDialogListPropostasDoPedido = true;
    },

    closeModalListPropostasDoPedido(){
      this.selectedPedido = null;
      this.showDialogListPropostasDoPedido = false;
    },

    openPedidoDetalhe(myItem){
      this.openDialogPedido(myItem);
    },

    deletePedido(myItemId){
      console.log('deletando pedido: ' + myItemId);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/delete/" + myItemId, {}, config)
        .then(response => {
          console.log('deletando o pedido: ' + response.status);
          store.globalSnackbarMessage = "Pedido deletado";
          store.globalSnackbar = true;
          this.loadPedidos();
      })
      .catch(error => {
        console.log('deletando pedido : ' + error.status);
        store.showDialogError = true;
        store.dialogLoader = false;
      });
    },

    filterMyItem(myItem){
      if(this.search && this.search.length >= 3){
        return myItem.dtCreated.toLowerCase().includes(this.search)
          || new String(myItem.originalPrice).toLowerCase().includes(this.search)
          || myItem.servico.nomeServico.toLowerCase().includes(this.search)
      }
      return true;
    },

    scrollToTheTop(){
      //window.scrollTo(0,0);

      const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
      this.$smoothScroll({
        scrollTo: myEl,
        offset: -100,
      });
    },

    openDialogPedido(myItem){
      this.selectedPedido = myItem;
      this.showDialogPedidoDetalhe = true;
    },

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
      this.selectedPedido = null;
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPedidos();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPedidos(){

      if(this.$route.params.pedidoStatus){
        switch (this.$route.params.pedidoStatus) {
          case 'ACCEPTED':
            this.pedidoList = store.pedidosEmAndamentoList;
            break;
          case 'RUNNING':
            this.pedidoList = store.pedidosEmExecucaoList;
            break;
          case 'PAID':
            this.pedidoList = store.pedidosEmExecucaoList;
            break;  
        }
      }

      // if(store.userLogged){
      //   store.dialogLoader = true;
      //   this.axios.get(services.serviceHost + "/pedido" + this.buildURL(), {
      //     headers: { Authorization: `Bearer ${store.currentToken}` }
      //   })
      //   .then(response => {
      //       console.log('meus pedidos ' + this.$route.params.pedidoStatus + ': ' + response.status);
      //       this.pedidoList = response.data;
      //       console.log('url : ' + services.serviceHost + "/pedido" + this.buildURL());
      //       console.log('total de ' + this.pedidoList.length + ' found');
      //       store.dialogLoader = false;
      //   })
      //   .catch(error => {
      //     console.log('count pedidos : ' + error.status);
      //     this.softLogout();
      //   });
      // }
    },

    buildURL(){

      if(this.$route.params.pedidoStatus){
        switch (this.$route.params.pedidoStatus) {
          case 'CREATED':
            return '/get/all/created';
          case 'ACCEPTED':
            return '/get/all/accepted';
          case 'RUNNIING':
            return '/get/all/running';
          case 'FINISHED_EXECUTED':
            return '/get/all/finished';
          default:
            return null;
        }
      }
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
