<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="6" md="8" sm="12">
      <v-card v-if="proposta" :disabled="store.dialogLoader || dialogConfirm || dialogSuccess" class="elevation-10">
        <v-toolbar color="primary">
          <v-toolbar-title>{{ proposta.servicoNome }}</v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-toolbar
          color="primary"
          :title="proposta.nomeServico"
        >
          
          <v-btn text large link to="/">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
        <v-card-text class="pa-4">
          <v-row>

            <!-- <v-col cols="12" class="text-md-h5 text-sm-body-1 text-center">
              Proposta
            </v-col> -->

            <v-col cols="12" class="text-center pb-0" v-if="proposta.prestadorId != store.userLogged.id">
              Avaliação deste Prestador<br />
              <v-rating
                half-increments
                readonly
                v-model="mockRating"
                color="primary"
              ></v-rating>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1">
              <v-text-field v-model="proposta.prestadorNome" 
                prepend-inner-icon="mdi-account-hard-hat"
                label="Prestador"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="proposta.pedidoOfferedValue">
              <v-text-field :model-value="'R$ ' + formataMoedaBRL(proposta.pedidoOfferedValue)"
                prepend-inner-icon="mdi-cash-multiple"
                label="Contratante Paga"
                color="primary"
                readonly></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="proposta.offeredPrice">
              <v-text-field :model-value="'R$ ' + formataMoedaBRL(proposta.offeredPrice)"
                prepend-inner-icon="mdi-cash-multiple"
                label="Valor da Proposta"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1">
              <v-text-field v-model="proposta.dtCreated" 
                prepend-inner-icon="mdi-calendar-text-outline"
                label="Data da Proposta"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="!proposta.acceptedPrestadorId">
              <v-text-field model-value="Em aberto"
                :prepend-inner-icon="proposta.acceptedPrestadorId == proposta.prestadorId ? 'mdi-check' : 'mdi-close-circle-outline'"
                label="Status da Proposta"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="proposta.acceptedPrestadorId">
              <v-text-field :model-value="proposta.acceptedPrestadorId == proposta.prestadorId ? 'Aceita' : 'Recusada'"
                :prepend-inner-icon="proposta.acceptedPrestadorId == proposta.prestadorId ? 'mdi-check' : 'mdi-close-circle-outline'"
                label="Status da Proposta"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId == store.userLogged.id">
              <v-text-field :model-value="proposta.nomeContratante ? proposta.nomeContratante : '-'"
                prepend-inner-icon="mdi-account-outline"
                label="Contratante"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId == store.userLogged.id">
              <v-text-field :model-value="proposta.telefoneContratante ? proposta.telefoneContratante.numero : 'não informado'" 
                prepend-inner-icon="mdi-cellphone-basic"
                label="Contato"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId && proposta.prestadorId == store.userLogged.id">
              <v-text-field v-model="proposta.enderecoShort.localidade"
                prepend-inner-icon="mdi-city"
                label="Cidade"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId && proposta.prestadorId == store.userLogged.id">
              <v-text-field v-model="proposta.enderecoShort.bairro"
                prepend-inner-icon="mdi-home-city"
                label="Bairro"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId && proposta.prestadorId == store.userLogged.id">
              <v-text-field :model-value="proposta.enderecoShort.logradouro + ', ' + proposta.enderecoShort.numero"
                prepend-inner-icon="mdi-home"
                label="Logradouro"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId && proposta.prestadorId == store.userLogged.id">
              <v-text-field :model-value="proposta.enderecoShort.complemento ? proposta.enderecoShort.complemento : '-'"
                prepend-inner-icon="mdi-door"
                label="Complemento"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="text-md-h5 text-sm-body1" v-if="(store.userLogged.roleAdmin) || proposta.acceptedPrestadorId && proposta.prestadorId == store.userLogged.id">
              <v-text-field :model-value="proposta.enderecoShort.pontoReferencia ? proposta.enderecoShort.pontoReferencia : '-'"
                prepend-inner-icon="mdi-map"
                label="Ponto de Referência"
                color="primary"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="text-md-h5 text-sm-body1">
              <v-textarea v-model="proposta.pedidoDescricao"
                label="Descrição"
                color="primary"
                readonly></v-textarea>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="text-md-h5 text-sm-body1" v-if="proposta.prestadorComment">
              <v-textarea v-model="proposta.prestadorComment"
                label="Comentário do Prestador"
                color="primary"
                readonly></v-textarea>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions class="pa-5" v-if="!proposta.acceptedPrestadorId && (proposta.prestadorId != store.userLogged.id)">
          <v-btn color="primary" class="px-4" width="100%"
            large variant="outlined" @click="dialogConfirm = true">
            Aceitar Proposta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>


    <v-dialog v-model="dialogConfirm" persistent transition="dialog-top-transition"
      max-width="400" :fullscreen="isSmallScreen">
      <v-card :disabled="dialogLoader">
        <v-toolbar color="primary">
          <v-toolbar-title>
            Confirma
          </v-toolbar-title>
          <v-btn icon large @click="dialogConfirm = false" color="white">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-center">
          <div>
            Aceitar proposta para 
            <span class="font-weight-black">{{ proposta.nomeServico }}</span> de 
            <span class="font-weight-black">{{ proposta.prestador }}</span>
          </div>
          <div class="mt-6">
            <v-icon size="50" color="primary">mdi-handshake-outline</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="px-4" width="100%"
            large variant="outlined" @click="acceptProposta()"> 
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogSuccess" persistent transition="dialog-top-transition"
      max-width="400" :fullscreen="isSmallScreen" :disabled="store.dialogLoader">
      <v-card :disabled="dialogLoader">
        <v-toolbar color="primary">
          <v-toolbar-title>
            Parabéns!
          </v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-center">
          <div>
            Estamos enviando os dados do prestador para o seu e-mail. 
          </div>
          <div class="mt-4">
            Você também pode visualizar os dados das suas propostas aceitas a qualquer momento, no menu 
            <span class="font-weight-black">"Meus Pedidos"</span>.
          </div>
          <div class="mt-6">
            <v-icon size="50" color="primary">mdi-handshake-outline</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="px-4" width="100%"
            large variant="outlined" link to="/"> 
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {

  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    proposta : null,

    mockRating : 3.5,

    dialogConfirm : false,
    dialogSuccess : false,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;


    console.log('proposta id: ' + this.$route.params.propostaId);
    this.loadProposta();
  },  

  mounted() {
    //this.simpleServiceCall();
  },

  methods: {

    acceptProposta(){

      console.log('salvando proposta');
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/accept/" + this.proposta.id + '/' + this.proposta.pedidoId, {}, config)
        .then(response => {
          console.log('proposta salva : ' + response.status);
          store.dialogLoader = false;
          this.dialogConfirm = false;

          //verificamos se ficou tudo ok:
          if(response.status == 200){
            this.dialogSuccess = true;
          }else{
            store.showDialogError = true;
          }

      })
      .catch(error => {
        console.log('proposta salva : ' + error.status);
        store.dialogLoader = false;
        this.dialogConfirm = false;
        store.showDialogError = true;
      });
    },

    loadProposta(){

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/load/detail/" + this.$route.params.propostaId, {}, config)
        .then(response => {
          //console.log('loading notifications : ' + response.status);
          this.proposta = response.data;
          //console.log('confirm url : ' + services.serviceHost + "/proposta/accept/" + this.proposta.id + '/' + this.proposta.pedidoId);
          //console.log('proposta : \n' + JSON.stringify(this.proposta));
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('loading proposta : ' + error.status);
        store.dialogLoader = false;
        store.showDialogError = true;
      });
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
