<template>

  <div>
    <div id="topoLista"></div>

    <v-row dense>

      <!-- <v-col cols="12" >
        <my-current-location />
      </v-col> -->

      <v-col class="pt-2 d-none d-sm-block"
          v-for="card in imageList"
          :key="card.id"
          cols="12" lg="4" md="6" sm="12"
        >
        <v-card elevation="5" class="fill-height">
          <v-img :src="card.url" class="align-center" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)" 
            cover>
            <v-card-text class="text-white text-right pb-12 font-weight-black text-body-2">
              <span class="">{{ card.text }}</span>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
      
      <v-expand-transition>
        <v-card class="elevation-5 mb-5 d-block d-sm-none font-weight-black" v-if="store.userLogged && store.userBankBalance && store.userBankBalance > 0"
          width="100%" color="grey">
          <v-toolbar color="grey" class="pr-4">
          <v-toolbar-title>
            <v-btn icon size="medium" link to="/user/bankaccount/all" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar-title>
          Seu saldo é de R$ {{ formataMoedaBRL(store.userBankBalance) }}
          </v-toolbar>
        </v-card>
      </v-expand-transition>
      

      <box-mosaico-home class="" :key="Math.random()"/>

      <v-expand-transition>
        <v-col cols="12" v-if="store.userLogged && store.pedidosEmAndamentoList.length > 0" class="mt-4">
          <v-banner icon="mdi-handshake" color="primary" lines="four" class="elevation-5">
            <template v-slot:prepend>
              <v-avatar></v-avatar>
            </template>
            <v-banner-text class="text-body-2 text-sm-h5">
              Você tem {{ store.pedidosEmAndamentoList.length }} 
              <span v-if="store.pedidosEmAndamentoList.length > 1">pedidos aceitos</span> 
              <span v-else>pedido aceito</span> 
              sem que o trabalho tenha sido iniciado. 
              Verifique e confirme o início ou término dos trabalhos.  
            </v-banner-text>
            <v-banner-actions>
              <v-btn link to="/pedido/list/filter/ACCEPTED">Veja Aqui</v-btn>
            </v-banner-actions>
          </v-banner>
          <!-- <v-card class="pa-4" color="yellow-lighten-4">
            Você tem {{ store.pedidosEmAndamentoList.length }} pedidos aceitos
          </v-card> -->
        </v-col>
      </v-expand-transition>

      <v-expand-transition>
        <v-col cols="12" v-if="store.userLogged && store.pedidosEmExecucaoList.length > 0" class="mt-4">
          <v-banner icon="mdi-account-hard-hat" color="primary" lines="four" class="elevation-5">
            <template v-slot:prepend>
              <v-avatar></v-avatar>
            </template>
            <v-banner-text class="text-body-2 text-sm-h5">
              Você tem {{ store.pedidosEmExecucaoList.length }} 
              <span v-if="store.pedidosEmExecucaoList.length > 1">trabalhos em execução.</span> 
              <span v-else>trabalho em execução</span> 
              Verifique e confirme o andamento ou término dos trabalhos.  
            </v-banner-text>
            <v-banner-actions>
              <v-btn link to="/pedido/list/filter/RUNNING">Veja Aqui</v-btn>
            </v-banner-actions>
          </v-banner>
          <!-- <v-card class="pa-4" color="yellow-lighten-4">
            Você tem {{ store.pedidosEmAndamentoList.length }} pedidos aceitos
          </v-card> -->
        </v-col>
      </v-expand-transition>

      <v-col cols="12">
        <box-busca-prestador @open-novo-pedido="openNovoPedido()" :key="Math.random()"/>
      </v-col>

      <v-col cols="12" class="px-0 pa-0">
        <box-lista-pedidos-abertos :key="Math.random()"/>
      </v-col>
      
      <v-col class="pt-2"
          v-for="card in imageList"
          :key="card.id"
          cols="12" lg="4" md="6" sm="12"
        >
        <v-card elevation="5" class="fill-height">
          <v-img :src="card.url" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
            height="200px" cover>
          </v-img>
        </v-card>
      </v-col>

      <modal-cria-pedido v-bind:show-dialog="showNovoPedidoModal" v-if="servicoList"
        @item-modal-close="pedidoModalClose()" v-bind:servico-available-list="servicoList"
        :key="Math.random()" :title-message="'Criar um pedido'" />
      
    </v-row>

    <v-expand-x-transition>
      <div class="floating-button text-center" v-if="position[1] > 80">
        <v-btn icon color="grey-darken-2" v-if="position[1] > 80" class="elevation-6" 
          @click="scrollToTheTop">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>

  </div>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';
import BoxBuscaPrestador from '@/components/common/BoxBuscaPrestador.vue';
import BoxListaPedidosAbertos from '@/components/common/BoxListaPedidosAbertos.vue';
//import MyCurrentLocation from '@/components/common/MyCurrentLocation.vue'

import ModalCriaPedido from '@/components/modal/ModalCriaPedido.vue';

import windowScrollPosition from '@/mixins/window-scroll-position';

import BoxMosaicoHome from '@/components/common/BoxMosaicoHome.vue';

export default {

    components: { 
      BoxBuscaPrestador, 
      ModalCriaPedido,
      BoxListaPedidosAbertos,
      BoxMosaicoHome,
      // MyCurrentLocation,
    },

    mixins: [
        myMixin,
        windowScrollPosition('position'),
    ],
    
    data: () => ({

      store,

      latitude: null,
      longitude: null,

      showNovoPedidoModal : false,
      servicoList : null,

      pedidoList : null,

      isSmallScreen : null,

      items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],

        imageList: [
            {
                id: 1,
                text: "TUDO O QUE VOCÊ PRECISA, AQUI",
                url: require("@/assets/img/home/nail-2.jpeg"),
            },
            {
                id: 2,
                text: "PAGUE APENAS APÓS O SERVIÇO CONCLUÍDO",
                url: require("@/assets/img/home/cook-2.jpeg"),
            },
            {
                id: 3,
                text: "OS MELHORES PROFISSIONAIS",
                url: require("@/assets/img/home/pintor-2.png"),
            },
            {
                id: 4,
                text: "SERVIÇOS PERSONALIZADOS PARA VOCÊ",
                url: require("@/assets/img/home/garden-2.png"),
            },
            {
                id: 5,
                text: "EM QUALQUER LUGAR",
                url: require("@/assets/img/home/pintor-3.png"),
            },
            {
                id: 6,
                text: "PRATICIDADE",
                url: require("@/assets/img/home/clean-floor.png"),
            },
            // {
            //     id: 7,
            //     text: "AGILIDADE",
            //     url: require("@/assets/img/home/car-wash-2.png"),
            // },
            // {
            //     id: 8,
            //     text: "CONTRATAÇÃO SIMPLES E RÁPIDA",
            //     url: require("@/assets/img/home/barbecue-1.jpeg"),
            // },
            // {
            //     id: 9,
            //     text: "COMECE AGORA!",
            //     url: require("@/assets/img/home/notebook-1.jpeg"),
            // },
        ]
    }),
    
    mounted() {
        this.loadBasicGlobalData();
        this.loadAllServicos();

        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
          },
          (error) => {
            console.log(error.message);
          }
        );
    },

    created(){
      this.preloadImages();
    },

    methods: {

      preloadImages() {
        this.imageList.forEach((image, index) => {
          const img = new Image();
          img.src = image.url;
          img.onload = () => {
            this.imageList[index].loaded = true;
            this.checkAllImagesLoaded();
          };
        });
      },

      checkAllImagesLoaded() {
        if (this.imageList.every(image => image.loaded)) {
          this.allImagesLoaded = true;
        }
      },

      beforeMount (){

        this.isSmallScreen = this.$vuetify.display.mobile;
      },  

      loadAllServicos(){

        if(!this.servicoList){
          //store.dialogLoader = true;
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/servico/enabled", {}, config)
            .then(response => {
              console.log('loading servicos : ' + response.status);
              this.servicoList = response.data;
              //store.dialogLoader = false;
          });
        }
      },

      pedidoModalClose(){
        console.log('fechou a modal');
        this.showNovoPedidoModal = false;
      },

      openNovoPedido(){
        console.log('abriu a modal');
        this.showNovoPedidoModal = true; 
      },

      simpleServiceCall() {
          const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .get(services.serviceHost + "/user/healthcheck", {}, config)
              .then(response => {
              console.log(response.data);
          });
          if (this.user && !this.user.id) {
              this.user.roles = [];
          }
      },

      scrollToTheTop(){
      //window.scrollTo(0,0);

      const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
      this.$smoothScroll({
        scrollTo: myEl,
        offset: -100,
      });
    },
},
    
}

</script>

<style scoped></style>
