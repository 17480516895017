<template>
    <div>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="4" sm="8">
                <v-dialog @click:outside="closeModal"
                    transition="dialog-bottom-transition"
                    :scrim="true"
                    max-width="500"
                    :fullscreen="isSmallScreen"
                    v-model="localShowDialog"
                    >
                    <v-card :disabled="store.dialogLoader" v-if="selectedItem">
                        <v-toolbar
                        color="primary"
                        >
                            <v-toolbar-title class="">
                                {{ titleMessage }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeModal">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="body-1 pa-6 pt-10">

                            <div class="body-1 mb-5 text-justify">
                              Vamos enviar um email com o um link de convite. Assim que o convite for aceito, você também será avisado.
                            </div>
                            
                            <v-text-field class="mb-5" variant="outlined" 
                                v-model="selectedItem.firstName"
                                id="firstName"
                                label="Nome do Convidado"
                                maxlength="20"
                                clearable
                                counter
                                on-update:focused="true"
                                v-on:keypress="isLetter($event)"
                                :error-messages="v$.selectedItem.firstName.$invalid ? ['Digite o nome'] : []">
                            </v-text-field>

                            <v-text-field class="mb-5" variant="outlined" 
                                v-model="selectedItem.email"
                                id="email"
                                label="Email"
                                maxlength="120"
                                clearable
                                counter
                                on-update:focused="true"
                                :disabled="v$.selectedItem.firstName.$invalid"
                                :error-messages="v$.selectedItem.email.$invalid ? ['Digite um email válido'] : []">
                            </v-text-field>

                            <v-checkbox v-model="selectedItem.admin" label="Permissão de Administrador" 
                              v-if="store.userLogged.roleAdmin"></v-checkbox>

                            <v-checkbox v-model="selectedItem.atendimento" label="Perfil de Financeiro" 
                              v-if="store.userLogged.roleAdmin" class="mt-n8"></v-checkbox>
                            
                            <v-checkbox v-model="selectedItem.financeiro" label="Permissão de Atendimento" 
                              v-if="store.userLogged.roleAdmin" class="mt-n8"></v-checkbox>


                        </v-card-text>

                        <v-card-actions class="pa-5 pt-0">
                        <v-spacer v-if="!isSmallScreen"></v-spacer>
                        <v-btn color="primary" class="px-4" :disabled="v$.selectedItem.$invalid"
                            @click="saveItem"
                            :width="isSmallScreen ? '100%' : ''"
                            arge variant="outlined">
                            Salvar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  //import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength, email } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  import User from '../entity/User';
  
  export default {

    setup () {
        return { v$: useVuelidate() }
    },

    props: {
        titleMessage : String,
        showDialog : Boolean,
    },
  
    mixins: [
      myMixin,
    ],

    components : {
      
    },  
  
    beforeMount (){

      this.isSmallScreen = this.$vuetify.display.mobile;
      this.selectedItem = new User();
    },

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    data: () => ({
        store,
        firstName : null,
        selectedItem : null,

        isSmallScreen : false,
    }),
  
    methods: {
  
      saveItem(){

        let myObject = {
          godfather : store.userLogged,
          nomeInvite : this.selectedItem.firstName,
          admin : this.selectedItem.admin,
          atendimento : this.selectedItem.atendimento,
          financeiro : this.selectedItem.financeiro,
          email : this.selectedItem.email,
        }

        this.$emit('item-modal-save', myObject);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('item-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            firstName : {
              required,
              min: minLength(3),
              max: maxLength(30),
            },
            email : {
              required, email
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  