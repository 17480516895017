<template>
  <div>
    <v-card :disabled="store.dialogLoader" v-if="propostaList">
      <v-toolbar color="primary">

        <v-toolbar-title>Total de {{ propostaList.length }} Propostas</v-toolbar-title>
        <v-btn icon large link to="/" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-card class="pa-2 mb-4">
          <v-text-field v-model="filter"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
            ></v-text-field>
        </v-card>
        
        <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="propostaList"
          class="elevation-1"
          :search="filter"
        >
        <template v-slot:[`item.dtCreated`]="{ item }">
          {{ item.raw.dtCreated }}
        </template>

        <template v-slot:[`item.aceita`]="{ item }">
          <v-btn variant="outlined" v-if="item.raw.acceptedPrestadorId" append-icon="mdi-magnify"
            class="text-none" color="primary" size="small" link :to="'/proposta/' + item.raw.id">
            <span v-if="item.raw.acceptedPrestadorId == item.raw.prestadorId">Aceita</span>
            <span v-if="item.raw.acceptedPrestadorId != item.raw.prestadorId">Recusada</span>
          </v-btn>
          <span v-if="!item.raw.acceptedPrestadorId">Em aberto</span>
        </template>

        <template v-slot:[`item.offeredPrice`]="{ item }">
          {{ (item.raw.offeredPrice ? 'R$ ' + formataMoedaBRL(item.raw.offeredPrice) : 'R$ ' + formataMoedaBRL(item.raw.pedidoOfferedValue)) }} 
        </template>

        <template v-slot:[`item.nomeContratante`]="{ item }">
          {{ item.raw.nomeContratante ? item.raw.nomeContratante : '-' }}
        </template>

        <template v-slot:[`item.prestadorNome`]="{ item }">
          {{ item.raw.prestadorNome ? item.raw.prestadorNome : '-' }}
        </template>
        
        <template v-slot:[`item.endereco`]="{ item }">
          {{ (item.raw.enderecoShort ? item.raw.enderecoShort.bairro + ', ' + item.raw.enderecoShort.uf : '-') }}
        </template>

        <template v-slot:[`item.cancelar`]="{ item }">
          <v-btn variant="text" x-small color="primary" :disabled="item.raw.aceita"
            @click="cancelarProposta(item.raw.id)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        
        
        </v-data-table>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import { VDataTable } from 'vuetify/labs/VDataTable';

export default {

  components : {
    VDataTable,
  },

  mixins: [
    myMixin,
  ],

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  data: () => ({

    headers : [
      { title: 'Serviço', key: 'servicoNome', sortable: true},
      { title: 'Status', key: 'aceita', sortable: true},
      { title: 'Contratante', key: 'nomeContratante', sortable: true},
      { title: 'Prestador', key: 'prestadorNome', sortable: true},
      { title: 'Local', key: 'endereco', sortable: true},
      { title: 'Valor', key: 'offeredPrice', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Cancelar', key: 'cancelar', sortable: true},
    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    propostaList : null,

    filter : null,

  }),

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadPropostas();
    this.loadBasicGlobalData();
  },

  methods: {

    cancelarProposta(myItemId){

      console.log('cancelando proposta : ' + myItemId);
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPropostas();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPropostas(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/all", {}, config)
        .then(response => {
          this.propostaList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
